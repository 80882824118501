import React from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Title,
  CardContainer,
  Card,
  TitleCard,
  ContentCard,
  Buttons,
  Online,
  Repository,
  ContainerScreen,
  TitleScreen,
  Screens,
  ButtonBack,
  NavLink,
} from './styles'

import { FaExternalLinkAlt, FaGithub } from 'react-icons/fa'
import { MdKeyboardBackspace } from 'react-icons/md'

import ScrollTopButton from '../../components/ScrollTopButton'

import TopBar from '../../components/TopBar'
import Head from '../../components/Head'

const ProjectDetail = ({ data }) => {
  const { titulo } = useParams()

  function ScrollTop() {
    window.scrollTo(0, 0)
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  ScrollTop()

  return (
    <Container>
      <TopBar />
      <ScrollTopButton />

      <ButtonBack>
        <NavLink to="/projetos">
          <MdKeyboardBackspace style={{ fontSize: '1.5rem' }} />
          Voltar
        </NavLink>
      </ButtonBack>

      {data
        .filter((dataProject) => dataProject.tituloURL === titulo)
        .map((dataProject, index) => (
          <div key={index}>
            <Head title={`${dataProject.titulo} - `} />
            <Title>
              <h1>
                {dataProject.titulo} ({dataProject.tipo})
              </h1>
            </Title>

            <CardContainer data-aos="fade-right">
              <Card>
                <TitleCard>
                  <h2>Informações do Projeto</h2>
                </TitleCard>

                <ContentCard>
                  <p>{dataProject.descricao}</p>
                  <p className="tecs">
                    Tecnologias usadas:<span>{dataProject.tecnologias}</span>
                  </p>

                  {dataProject.funcionalidades !== '' && (
                    <p className="func">
                      Funcionalidades:
                      <span>{dataProject.funcionalidades}</span>
                    </p>
                  )}

                  {dataProject.api !== '' && (
                    <p className="api">
                      API:
                      <a
                        href={dataProject.api}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Ver a API do projeto
                      </a>
                    </p>
                  )}

                  <p className="date">
                    Criado em: <span>{dataProject.dataCriacao}</span>
                  </p>

                  <Buttons>
                    <Repository href={dataProject.repositorio} target="_blank">
                      <FaGithub
                        style={{ marginRight: '10px', fontSize: '1rem' }}
                      />
                      Ver repositório no Github
                    </Repository>

                    {dataProject.online !== '' && (
                      <Online href={dataProject.online} target="_blank">
                        <FaExternalLinkAlt
                          style={{ marginRight: '10px', fontSize: '1rem' }}
                        />
                        Ver projeto online
                      </Online>
                    )}
                  </Buttons>
                </ContentCard>
              </Card>
            </CardContainer>

            <ContainerScreen>
              <TitleScreen style={{ width: 'max-content' }}>
                <h1>Telas</h1>
              </TitleScreen>
            </ContainerScreen>

            {dataProject.tituloURL === 'sistema-chamados' && (
              <Screens data-aos="fade-up">
                <h3 className="title">Login</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaLogin}
                  alt={dataProject.titulo}
                />

                <h3>Cadastro de Usuários</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaCadastroUsuarios}
                  alt={dataProject.titulo}
                />

                <h3>Home</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaHome}
                  alt={dataProject.titulo}
                />

                <h3>Novo Cliente</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaNovoCliente}
                  alt={dataProject.titulo}
                />

                <h3>Editar Cliente</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaEditarCliente}
                  alt={dataProject.titulo}
                />

                <h3>Excluir Cliente</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaExcluirCliente}
                  alt={dataProject.titulo}
                />

                <h3>Configurações da Conta</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaConfigConta}
                  alt={dataProject.titulo}
                />

                <h3>Novo Chamado</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaNovoChamado}
                  alt={dataProject.titulo}
                />

                <h3>Home com chamados cadastrados</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaChamadosCadastrados}
                  alt={dataProject.titulo}
                />

                <h3>Modal detalhes do chamado</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaDetalhesChamados}
                  alt={dataProject.titulo}
                />

                <h3>Editar Chamado</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaEditarChamado}
                  alt={dataProject.titulo}
                />

                <h3>Excluir Chamado</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaExcluirChamado}
                  alt={dataProject.titulo}
                />
              </Screens>
            )}

            {dataProject.tituloURL === 'receitas-mobile' && (
              <Screens data-aos="fade-up">
                <div className="mobile" data-aos="fade-up">
                  <h3>Home</h3>
                  <img
                    className="img-receitas"
                    src={dataProject.telaHome}
                    alt={dataProject.titulo}
                  />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Detalhes da receita</h3>
                  <img
                    className="img-receitas"
                    src={dataProject.telaDetalhesReceita}
                    alt={dataProject.titulo}
                  />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Vídeo da receita</h3>
                  <img
                    className="img-receitas"
                    src={dataProject.telaVideoReceita}
                    alt={dataProject.titulo}
                  />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Favoritos (Vazia)</h3>
                  <img
                    className="img-receitas"
                    src={dataProject.telaFavoritosVazia}
                    alt={dataProject.titulo}
                  />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Favoritos</h3>
                  <img
                    className="img-receitas"
                    src={dataProject.telaFavoritos}
                    alt={dataProject.titulo}
                  />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Pesquisa</h3>
                  <img
                    className="img-receitas"
                    src={dataProject.telaPesquisa}
                    alt={dataProject.titulo}
                  />
                </div>
              </Screens>
            )}

            {dataProject.tituloURL === 'netflix-mobile' && (
              <Screens>
                <div className="mobile" data-aos="fade-up">
                  <h3>Login</h3>
                  <img
                    className="img-login"
                    src={dataProject.telaLogin}
                    alt={dataProject.titulo}
                  />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Home</h3>
                  <img src={dataProject.telaHome1} alt={dataProject.titulo} />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Home</h3>
                  <img src={dataProject.telaHome2} alt={dataProject.titulo} />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Detalhes do Filme ou Série</h3>
                  <img
                    src={dataProject.telaDetalhes1}
                    alt={dataProject.titulo}
                  />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Seção de Filmes Semelhantes</h3>
                  <img
                    src={dataProject.telaDetalhes2}
                    alt={dataProject.titulo}
                  />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Ao clicar nos icones do menu de baixo</h3>
                  <img src={dataProject.telaTabMenu} alt={dataProject.titulo} />
                </div>
              </Screens>
            )}

            {dataProject.tituloURL === 'netflix-web' && (
              <Screens data-aos="fade-up">
                <h3 className="title">Página Home</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaHome}
                  alt={dataProject.titulo}
                />
                <h3>
                  Modal ao clicar em assistir ou em cima de qualquer filme:
                </h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaModal}
                  alt={dataProject.titulo}
                />
              </Screens>
            )}

            {dataProject.tituloURL === 'waiter-app-web' && (
              <Screens data-aos="fade-up">
                <h3 className="title">Lista de Pedidos</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaHome}
                  alt={dataProject.titulo}
                />
                <h3>Detalhes do pedido</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaModal}
                  alt={dataProject.titulo}
                />
                <h3>Ao alterar o status do pedido</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaStatus}
                  alt={dataProject.titulo}
                />
              </Screens>
            )}

            {dataProject.tituloURL === 'waiter-app-mobile' && (
              <Screens data-aos="fade-up">
                <div className="mobile" data-aos="fade-up">
                  <h3>Home</h3>
                  <img src={dataProject.telaHome} alt={dataProject.titulo} />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Detalhes do produto</h3>
                  <img src={dataProject.telaProduto} alt={dataProject.titulo} />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Informar número da mesa</h3>
                  <img src={dataProject.telaMesa} alt={dataProject.titulo} />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Carrinho de compras</h3>
                  <img
                    src={dataProject.telaCarrinho}
                    alt={dataProject.titulo}
                  />
                </div>

                <div className="mobile" data-aos="fade-up">
                  <h3>Pedido Confirmado</h3>
                  <img src={dataProject.telaPedido} alt={dataProject.titulo} />
                </div>
              </Screens>
            )}

            {dataProject.tituloURL === 'dev-link' && (
              <Screens data-aos="fade-up">
                <h3 className="title">Página Home</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaHome}
                  alt={dataProject.titulo}
                />

                <h3>
                  Página de Login e Mensagem caso o Login ou Senha estejam
                  errados
                </h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaLogin}
                  alt={dataProject.titulo}
                />

                <h3>Página admin, para Cadastrar e Deletar Links</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaCadastrar}
                  alt={dataProject.titulo}
                />

                <h3>Página de Editar Redes Sociais</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaEditar}
                  alt={dataProject.titulo}
                />
              </Screens>
            )}

            {dataProject.tituloURL === 'tela-instagram' ||
            dataProject.tituloURL === 'financas' ? (
              <Screens data-aos="fade-up">
                <div className="mobile" style={{ height: '850px' }}>
                  <h3>Home</h3>
                  <img
                    data-aos="fade-up"
                    src={dataProject.telaHome}
                    alt={dataProject.titulo}
                  />
                </div>
              </Screens>
            ) : null}

            {dataProject.tituloURL === 'movies' && (
              <Screens data-aos="fade-up">
                <h3 className="title">Página Home</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaHome}
                  alt={dataProject.titulo}
                />
                <h3>Página de detalhes do filme</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaDetalhes}
                  alt={dataProject.titulo}
                />
              </Screens>
            )}

            {dataProject.tituloURL === 'dogs' && (
              <Screens data-aos="fade-up">
                <h3 className="title">Página Home</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaHome}
                  alt={dataProject.titulo}
                />

                <h3>Página de Login</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaLogin}
                  alt={dataProject.titulo}
                />

                <h3>Página de Recuperar Senha</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaRecuperar}
                  alt={dataProject.titulo}
                />

                <h3>Página de Cadastro</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaCadastrar}
                  alt={dataProject.titulo}
                />

                <h3>Página Minha Conta</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaConta}
                  alt={dataProject.titulo}
                />

                <h3>Página para Postar Fotos</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaPostar}
                  alt={dataProject.titulo}
                />

                <h3>Página Minha Conta após postar fotos</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaContaPost}
                  alt={dataProject.titulo}
                />

                <h3>Modal de detalhes do post</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaModal}
                  alt={dataProject.titulo}
                />

                <h3>Página de Estatísticas</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaEstatisticas}
                  alt={dataProject.titulo}
                />

                <h3>Página Home depois de postar fotos</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaHomeFoto}
                  alt={dataProject.titulo}
                />
              </Screens>
            )}

            {dataProject.tituloURL === 'bikcraft-2' && (
              <Screens data-aos="fade-up">
                <h3 className="title">Página Home</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaHome}
                  alt={dataProject.titulo}
                />

                <h3>Página Bicicletas</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaBicicletas}
                  alt={dataProject.titulo}
                />

                <h3>Página Bicicleta</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaBicicleta}
                  alt={dataProject.titulo}
                />

                <h3>Página de Seguro</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaSeguro}
                  alt={dataProject.titulo}
                />

                <h3>Página de Orçamento</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaOrcamento}
                  alt={dataProject.titulo}
                />

                <h3>Página de Contato</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaContato}
                  alt={dataProject.titulo}
                />
              </Screens>
            )}

            {dataProject.tituloURL === 'portfolio-antigo' ||
            dataProject.tituloURL === 'ranek' ||
            dataProject.tituloURL === 'animais-fantasticos' ||
            dataProject.tituloURL === 'portfolio' ||
            dataProject.tituloURL === 'bikcraft' ||
            dataProject.tituloURL === 'wildbeast' ||
            dataProject.tituloURL === 'burger' ||
            dataProject.tituloURL === 'flexblog' ||
            dataProject.tituloURL === 'hotel-paraiso' ? (
              <Screens data-aos="fade-up">
                <h3 className="title">Página Home</h3>
                <img
                  data-aos="fade-up"
                  className="img-web"
                  src={dataProject.telaHome}
                  alt={dataProject.titulo}
                />
              </Screens>
            ) : null}
          </div>
        ))}
    </Container>
  )
}

export default ProjectDetail
