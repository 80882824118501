import React, { useState } from 'react'
import { Container, Content, Details, Header, ModalDiv } from './styles'
import { BsDownload } from 'react-icons/bs'
import Button from '../Button'

const Modal = ({ id = 'modal', onClose = () => {}, certificateSelected }) => {
  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose()
  }

  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <ModalDiv id={id} onClick={handleOutsideClick}>
      <Container>
        <button onClick={onClose} />
        <Content>
          <img
            src={certificateSelected.imagemModal}
            alt={certificateSelected.titulo}
          />

          <Header>
            <h3>
              {certificateSelected.titulo}
              <span> ({certificateSelected.instituicao})</span>
            </h3>
            <Button
              href={certificateSelected.href}
              download={certificateSelected.download}
              type="appication/pdf"
              style={{
                backgroundColor: isHover ? '#ff2c5f' : '#111',
                marginBottom: '0px',
                border: '2px solid #ff2c5f',
                transform: isHover ? 'scale(1)' : 'scale(1)',
                fontSize: isHover ? '0.9rem' : '0.9rem',
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <BsDownload style={{ fontSize: '1.2rem' }} />
              Download
            </Button>
          </Header>

          <Details>
            <p>{certificateSelected.descricao}</p>
            <h4>
              Aprendizados: <span>{certificateSelected.aprendizados}</span>
            </h4>
          </Details>
        </Content>
      </Container>
    </ModalDiv>
  )
}

export default Modal
