import React, { useRef } from 'react'
import { Nav, NavLink, Bars, Logo, NavMenu } from './styles'
import LoadingBar from 'react-top-loading-bar'

const Header = ({ setMenuIsVisible }) => {
  const ref = useRef(null)

  const handleLoadSomething = () => {
    ref.current.staticStart()
    setTimeout(() => {
      ref.current.complete()
    }, 1000)
  }

  return (
    <>
      <Nav>
        <NavLink to="/" className="logo" onClick={handleLoadSomething}>
          <Logo>
            <h2>BM</h2>
          </Logo>
        </NavLink>
        <Bars onClick={() => setMenuIsVisible(true)} />
        <NavMenu>
          <NavLink to="/" onClick={handleLoadSomething}>
            Home
          </NavLink>
          <NavLink to="/sobre" onClick={handleLoadSomething}>
            Sobre
          </NavLink>
          <NavLink to="/projetos" onClick={handleLoadSomething}>
            Projetos
          </NavLink>
          <NavLink to="/contato" onClick={handleLoadSomething}>
            Contato
          </NavLink>
        </NavMenu>
      </Nav>
      <LoadingBar color="#ff2c5f" height="4px" ref={ref} />
    </>
  )
}

export default Header
