import React, { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Autoplay, Pagination, Navigation } from 'swiper'

import '../../global.css'

//certificados imgs
// import ReactCompleto from '../../assets/certificados/react-completo.png'
// import Javascript from '../../assets/certificados/js.png'
// import HtmlCss from '../../assets/certificados/html-css.png'
// import CssGrid from '../../assets/certificados/css-grid.png'
// import CssFlex from '../../assets/certificados/css-flex.png'

//certificados imgs modal
// import ReactCompletoModal from '../../assets/certificados/modal/react-completo-600w.png'
// import JavascriptModal from '../../assets/certificados/modal/js-600w.png'
// import HtmlCssModal from '../../assets/certificados/modal/html-css-600w.png'
// import CssGridModal from '../../assets/certificados/modal/css-grid-600w.png'
// import CssFlexModal from '../../assets/certificados/modal/css-flex-600w.png'

//certificados pdf
import CertificadoReact from '../../certificates/certificate-react.pdf'
import CertificadoJS from '../../certificates/certificate-js.pdf'
import CertificadoHtmlCss from '../../certificates/certificate-html-css.pdf'
import CertificadoCssGrid from '../../certificates/certificate-grid.pdf'
import CertificadoCssFlex from '../../certificates/certificate-flex.pdf'

import Modal from '../CertificateModal'

const Certificate = () => {
  const [certificateSelected, setCertificateSelected] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  function handleDetails(certificate) {
    setIsModalVisible(true)
    setCertificateSelected(certificate)
  }

  const certificates = [
    {
      id: 1,
      imagem:
        'https://user-images.githubusercontent.com/98993736/213896204-1d11253d-a514-42ba-a788-590c39547e1e.png',
      imagemModal:
        'https://user-images.githubusercontent.com/98993736/213896359-8e0b7055-ce29-448b-9d02-1e77992fd1e3.png',
      titulo: 'React Completo',
      instituicao: 'Origamid',
      descricao:
        'O foco do curso é ensinar a como utilizar o React do zero para o desenvolvimento de aplicações web reativas. No projeto do curso, aprendi a criar um aplicativo web com funcionalidades parecidas de redes sociais como o Instagram.',
      aprendizados: 'React, HTML, CSS',
      href: `${CertificadoReact}`,
      download: 'certificate-react.pdf',
    },
    {
      id: 2,
      imagem:
        'https://user-images.githubusercontent.com/98993736/213896220-56809125-1664-4eef-8f1a-af282ad32407.png',
      imagemModal:
        'https://user-images.githubusercontent.com/98993736/213896393-ebe8c1a1-f136-4a14-86e6-4cada2732a62.png',
      titulo: 'JavaScript Completo ES6',
      instituicao: 'Origamid',
      descricao:
        'O foco do curso é ensinar tudo que é necessário para dominar o JavaScript. O curso vai do básico ao avançado e ao final dele você terá uma compreensão completa da linguagem, dos seus principais métodos e da sua sintaxe.',
      aprendizados: 'JavaScript',
      href: `${CertificadoJS}`,
      download: 'certificate-js.pdf',
    },
    {
      id: 3,
      imagem:
        'https://user-images.githubusercontent.com/98993736/213896234-6c9a9b31-b1a8-4010-97ca-675cc0d0ad61.png',
      imagemModal:
        'https://user-images.githubusercontent.com/98993736/213896422-f58fbc98-e712-4d8a-97da-4cb29e1216d7.png',
      titulo: 'HTML e CSS para Iniciantes',
      instituicao: 'Origamid',
      descricao:
        'O foco do curso é ensinar html e css do iniciante ao avançado. O curso começa com css antigo (float), depois vai para css moderno (flexbox e grid layout) e o básico de javascript e no final é construído um projeto prático.',
      aprendizados: 'HTML, CSS(Flex e Grid), JavaScript',
      href: `${CertificadoHtmlCss}`,
      download: 'certificate-html-css.pdf',
    },
    {
      id: 4,
      imagem:
        'https://user-images.githubusercontent.com/98993736/213896252-49797518-453b-416d-9bc4-47e3fbace869.png',
      imagemModal:
        'https://user-images.githubusercontent.com/98993736/213896427-395f04a0-0b9a-4801-aa34-3af6ef3fb9dd.png',
      titulo: 'CSS Grid Layout',
      instituicao: 'Origamid',
      descricao:
        'O foco do curso é ensinar CSS Grid Layout. O curso vai do básico ao avançado e ao final dele é construído um projeto prático mostrando as diferenças entre ele e o css flexbox.',
      aprendizados: 'HTML, CSS (Grid Layout)',
      href: `${CertificadoCssGrid}`,
      download: 'certificate-grid.pdf',
    },
    {
      id: 5,
      imagem:
        'https://user-images.githubusercontent.com/98993736/213896265-56afed2a-016e-4046-a125-abad42d286b8.png',
      imagemModal:
        'https://user-images.githubusercontent.com/98993736/213896439-bb806ad6-86c0-4358-97c4-3d4d885084d4.png',
      titulo: 'CSS Flexbox',
      instituicao: 'Origamid',
      descricao:
        'O foco do curso é ensinar CSS Flexbox. O curso vai do básico ao avançado e ao final dele é construído um projeto prático mostrando as diferenças entre ele e o css grid layout.',
      aprendizados: 'HTML, CSS (Flexbox)',
      href: `${CertificadoCssFlex}`,
      download: 'certificate-flex.pdf',
    },
  ]

  return (
    <>
      <Swiper
        slidesPerView={3}
        loop={true}
        centeredSlides={false}
        spaceBetween={30}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        className="mySwiper"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          530: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          630: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        }}
      >
        {certificates.map((certificate) => {
          return (
            <SwiperSlide className="slide" key={certificate.id}>
              <img
                className="image"
                src={certificate.imagem}
                alt={certificate.titulo}
                onClick={() => handleDetails(certificate)}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      {isModalVisible ? (
        <Modal
          onClose={() => setIsModalVisible(false)}
          certificateSelected={certificateSelected}
        />
      ) : null}
    </>
  )
}

export default Certificate
