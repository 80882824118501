// cores do site
export const primaryColor = '#111'

export const secondaryColor = '#ff2c5f'

export const headerCardColor = '#000'

export const cardColor = '#1c1b1b'

export const whiteColorText = '#fff'

export const boxShadowColor = 'rgba(0, 0, 0, 0.5)'

//projectDetail
export const borderButtonColor = '#ccc'
export const colorButtonOnline = '#ca1945'
export const colorButtonRepository = '#222'

//cor do botão fale comigo, home
export const buttonTalk = '#2a91ff'
export const buttonTalkHover = '#1f60a5'

//modal dos certificados
export const bgColorModal = 'rgba(0, 0, 0, 0.7)'
export const colorTextModal = '#e8e5e5df'

//formação em aprendizados, sobre
export const colorTextGray = '#bbb'
