import React, { useRef } from 'react'
import { Container, Nav, NavLink } from './styles'
import { MdOutlineClose } from 'react-icons/md'
import LoadingBar from 'react-top-loading-bar'

const HeaderMobile = ({ menuIsVisible, setMenuIsVisible }) => {
  function closeMenu() {
    setMenuIsVisible(false)
  }

  const ref = useRef(null)

  const handleLoadSomething = () => {
    ref.current.staticStart()
    setTimeout(() => {
      ref.current.complete()
    }, 1000)
  }

  function twoFunctions() {
    closeMenu()
    handleLoadSomething()
  }

  return (
    <>
      <Container isVisible={menuIsVisible}>
        <MdOutlineClose
          size={45}
          style={{ cursor: 'pointer' }}
          onClick={() => setMenuIsVisible(false)}
        />
        <Nav>
          <NavLink to="/" onClick={twoFunctions}>
            Home
          </NavLink>
          <NavLink to="/sobre" onClick={twoFunctions}>
            Sobre
          </NavLink>
          <NavLink to="/projetos" onClick={twoFunctions}>
            Projetos
          </NavLink>
          <NavLink to="/contato" onClick={twoFunctions}>
            Contato
          </NavLink>
        </Nav>
      </Container>
      <LoadingBar color="#ff2c5f" height="4px" ref={ref} />
    </>
  )
}

export default HeaderMobile
