import React from 'react'
import { Card, Container, Content, Description, ImgBox, Title } from './styles'

// import Html from '../../assets/tecnologias/html5.png'
// import Css from '../../assets/tecnologias/css3.png'
// import Bootstrap from '../../assets/tecnologias/bootstrap.png'
// import Javascript from '../../assets/tecnologias/javascript.png'
// import Typescript from '../../assets/tecnologias/typescript.png'
// import ReactJS from '../../assets/tecnologias/react.png'
// import ReactNative from '../../assets/tecnologias/reactNative.png'
// import Styled from '../../assets/tecnologias/styledcomponents.png'
// import NodeJS from '../../assets/tecnologias/nodejs.png'
// import Figma from '../../assets/tecnologias/figma.png'
// import MongoDB from '../../assets/tecnologias/mongodb.png'
// import Git from '../../assets/tecnologias/git.png'
// import Firebase from '../../assets/tecnologias/firebase.png'
// import Php from '../../assets/tecnologias/php.png'
// import Laravel from '../../assets/tecnologias/laravel.png'
// import PostgreSql from '../../assets/tecnologias/postgresql.png'
//import MySql from '../../assets/tecnologias/mysql.png'

const tecs = [
  {
    id: 1,
    icone:
      'https://user-images.githubusercontent.com/98993736/213889417-49340680-7ee8-4233-a486-de7224dbd7c5.png',
    titulo: 'HTML 5',
    corTitulo: '#ff5722',
    descricao: '5 anos de experiência',
  },
  {
    id: 2,
    icone:
      'https://user-images.githubusercontent.com/98993736/213890318-993adfd5-63ec-47f6-aeff-9d51fd0f8422.png',
    titulo: 'CSS 3',
    corTitulo: '#0089CA',
    descricao: '5 anos de experiência',
  },
  {
    id: 3,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896093-3e1dc22e-2439-42ed-a9a7-9a324c9d61cb.png',
    titulo: 'Git',
    corTitulo: '#e44c30',
    descricao: '4 anos de experiência',
  },
  {
    id: 4,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896022-736cd01a-ee6d-4af3-a164-eba3c5e27c59.png',
    titulo: 'JavaScript',
    corTitulo: '#F8DC3E',
    descricao: '3 anos de experiência',
  },
  {
    id: 5,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896009-bb685f4d-0787-4b74-bd6b-a0e463647318.png',
    titulo: 'Bootstrap',
    corTitulo: '#7952B3',
    descricao: '2 anos de experiência',
  },
  {
    id: 6,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896111-165b7e19-1a1a-4500-8cd8-e96d0d44247d.png',
    titulo: 'PHP',
    corTitulo: '#8694C1',
    descricao: '2 anos de experiência',
  },
  {
    id: 7,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896113-3057b603-1bb4-4a8b-ad69-06092646d498.png',
    titulo: 'Laravel',
    corTitulo: '#FF2A1A',
    descricao: '2 anos de experiência',
  },
  {
    id: 8,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896116-87d43fad-ad0d-4ad0-80d7-901202609c38.png',
    titulo: 'PostgreSql',
    corTitulo: '#336791',
    descricao: '2 anos de experiência',
  },
  {
    id: 9,
    icone:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/bd5ef5ac-d3ae-4231-b51a-dc612ae7d223',
    titulo: 'MySQL',
    corTitulo: '#00618A',
    descricao: '1 ano de experiência',
  },
  {
    id: 10,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896046-e99acb31-0766-4695-b061-0a0fa3f751c2.png',
    titulo: 'ReactJS',
    corTitulo: '#61dbfb',
    descricao: '1 ano de experiência',
  },
  {
    id: 11,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896062-20ab9d5b-a099-4f37-9c7c-03fd8674e00a.png',
    titulo: 'Styled Components',
    corTitulo: '#E58D7D',
    descricao: '1 ano de experiência',
  },
  {
    id: 12,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896086-daaabe29-5cda-487c-bf81-56ecb402509d.png',
    titulo: 'Figma',
    corTitulo: '#fff',
    descricao: '1 ano de experiência',
  },
  {
    id: 13,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896055-91d4a135-f7e3-4f90-b8e0-47811f5ad421.png',
    titulo: 'React Native',
    corTitulo: '#3f449f',
    descricao: '6 meses de experiência',
  },
  {
    id: 14,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896068-ec43769c-06ee-489c-91d0-38978de1c21e.png',
    titulo: 'NodeJS',
    corTitulo: '#80BC01',
    descricao: '6 meses de experiência',
  },
  {
    id: 15,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896075-67b84ccf-78fa-4cb0-949f-250658c3d228.png',
    titulo: 'Mongo DB',
    corTitulo: '#409131',
    descricao: '6 meses de experiência',
  },
  {
    id: 16,
    icone:
      'https://user-images.githubusercontent.com/98993736/213896031-ffe9a3ef-23a8-4baa-b4c4-0c2da9ec4108.png',
    titulo: 'TypeScript',
    corTitulo: '#2e72bc',
    descricao: '3 meses de experiência',
  },
  // {
  //   id: 17,
  //   icone:
  //     'https://user-images.githubusercontent.com/98993736/213896096-73312878-3747-4705-85d6-fe041da812b6.png',
  //   titulo: 'Firebase',
  //   corTitulo: '#FFCA28',
  //   descricao: '1 mês de experiência',
  // },
]

const Technology = () => {
  return (
    <Container>
      {tecs.map((technology) => {
        return (
          <Card key={technology.id}>
            <ImgBox
              style={{ borderRight: `3px solid ${technology.corTitulo}` }}
            >
              <img
                src={technology.icone}
                alt={technology.titulo}
                style={{ width: '70px' }}
              />
            </ImgBox>
            <Content>
              <Title style={{ color: technology.corTitulo }}>
                {technology.titulo}
              </Title>
              <Description>{technology.descricao}</Description>
            </Content>
          </Card>
        )
      })}
    </Container>
  )
}

export default Technology
