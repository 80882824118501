import React from 'react'
import { Company, CompanyDetails, Container, Office } from './styles'

const Experience = () => {
  const iconLansolver =
    'https://github.com/BrunoMonteiro25/chamados/assets/98993736/28b975a3-19fa-44f1-b719-49bed0365c31'

  return (
    <Container data-aos="fade-up">
      <Company>
        <div>
          <img src={iconLansolver} alt="Lansolver" />
        </div>
        <CompanyDetails>
          <h3>Lan Solver - Produtividade para equipes de campo</h3>
          <span>1 ano e 9 meses</span>
          <p>Barueri, São Paulo, Brasil</p>
        </CompanyDetails>
      </Company>

      <Office>
        <h4>Desenvolvedor Full Stack - Júnior</h4>
        <span>Tempo Integral</span>
        <p className="time">jan de 2020 - out de 2020 · 10 meses</p>
        <p className="description">
          Atuava no desenvolvimento de novos projetos e realizava manutenção em
          códigos já existentes nas linguagens:{' '}
          <span className="color-tec">
            HTML 5, CSS3, Javascript, Bootstrap, PHP(Laravel), PostgreSql, MySQL
          </span>
          . Utilizava <span className="color-tec">Git</span> e{' '}
          <span className="color-tec">Github</span> para o controle de
          versionamento dos projetos. Criava aplicações mobile utilizando uma
          plataforma low-code.
        </p>
      </Office>

      <Office>
        <h4>Desenvolvedor Full Stack - Estágio</h4>
        <span>Estágio</span>
        <p className="time">fev de 2019 - dez de 2019 · 11 meses</p>
        <p className="description">
          Utilizando uma plataforma de desenvolvimento low-code, criava
          aplicações mobile apenas com lógica de programação e prestava suporte
          ao cliente em relação a bugs nos sistemas da empresa.
        </p>
      </Office>
    </Container>
  )
}

export default Experience
