import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

import {
  borderButtonColor,
  buttonTalk,
  buttonTalkHover,
  whiteColorText,
} from '../../colors'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80vh;
  /* border: 1px solid white; */
  max-width: 70rem;
  margin: 95px auto 0 auto;

  @media screen and (max-width: 730px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`

export const Informations = styled.div`
  padding-left: 0.5rem;
  justify-content: center;
  /* border: 1px solid white; */

  @media screen and (max-width: 1150px) {
    padding-left: 1rem;
  }

  @media screen and (max-width: 768px) {
    padding-left: 1.2rem;
  }

  @media screen and (max-width: 730px) {
    margin-top: 40px;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    line-height: 1.3;
    font-weight: 600;
  }

  @media screen and (max-width: 395px) {
    h1 {
      font-size: 2rem;
    }
  }

  span {
    font-size: 1rem;
    color: ${borderButtonColor};
    padding-left: 0.2rem;
  }
`

export const Buttons = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media screen and (max-width: 320px) {
    width: 100%;
    gap: 0.5rem;
    margin-left: 2px;
    margin-right: 5px;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  color: ${whiteColorText};
  font-weight: bold;
  max-width: max-content;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 1.5rem;
  background: ${buttonTalk};

  &:hover {
    transform: scale(1.1);
    font-size: 1rem;
    background: ${buttonTalkHover};
  }
`

export const Image = styled.div`
  z-index: 1;
  /* border: 1px solid white; */

  @media screen and (max-width: 395px) {
    .img-mobile {
      width: 210px;
      margin-top: 20px;
    }
  }
`
