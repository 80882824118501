import styled from 'styled-components'

import { whiteColorText, boxShadowColor, cardColor } from '../../colors'

export const Container = styled.div`
  /* border: 1px solid white; */
  height: max-content;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 3.5rem 2.5rem;

  @media screen and (max-width: 1119px) {
    justify-content: center;
  }
`

export const Card = styled.div`
  /* border: 1px solid white; */
  position: relative;
  width: 250px;
  color: ${whiteColorText};
  height: 140px;
  background-color: ${cardColor};
  margin: 0;
  border-radius: 20px;
  box-shadow: 0 5px 15px ${boxShadowColor};
`

export const ImgBox = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 0 0 20px;
  width: 100px;
  height: 140px;
`

export const Content = styled.div`
  /* border: 1px solid white; */
  position: absolute;
  right: 0;
  width: calc(100% - 100px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h3`
  font-size: 1em;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
`

export const Description = styled.p`
  font-size: 0.7rem;
  text-align: center;
  margin-top: 5px;
  font-weight: 400;
`
