import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

import { primaryColor, secondaryColor, whiteColorText } from '../../colors'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${primaryColor};
  z-index: 1001;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid white; */

  h1 {
    color: ${secondaryColor};
    font-size: 6rem;
    letter-spacing: 3px;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    margin-top: 20px;
    font-size: 1.5rem;
    font-weight: 400;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1rem;
  padding: 0.6rem 2.1rem 0.6rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  color: ${whiteColorText};
  font-weight: bold;
  max-width: max-content;
  border: 1px solid ${secondaryColor};
  cursor: pointer;
  transition: all 0.7s;
  background: ${primaryColor};
  letter-spacing: 3px;
  margin-top: 50px;

  :hover {
    background-color: ${secondaryColor};
  }
`
