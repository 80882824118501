import styled, { css } from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

import { primaryColor, secondaryColor, whiteColorText } from '../../colors'

export const Container = styled.div`
  position: fixed;
  /* backdrop-filter: blur(3px); */
  height: 100%;
  width: 100%;
  z-index: 1000 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${primaryColor};
  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);
  transition: 0.5s;

  > svg {
    position: absolute;
    top: 1rem;
    right: 1.3rem;
    transform: rotate(45deg);
    transition: 0.7s;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0px);

      > svg {
        transform: rotate(0deg);
      }
    `}
`

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
`

export const NavLink = styled(Link)`
  color: ${whiteColorText};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 1.5rem;

  &.active {
    color: ${secondaryColor};
    font-weight: bold;
  }
`
