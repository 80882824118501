import React from 'react'
import ProjectCard from '../../components/ProjectCard'
import ScrollTopButton from '../../components/ScrollTopButton'
import { Container, Title } from './styles'

import TopBar from '../../components/TopBar'

import { data } from '../../services/api'
import Head from '../../components/Head'

const Projects = () => {
  function ScrollTop() {
    window.scrollTo(0, 0)
    //window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  ScrollTop()

  return (
    <Container>
      <Head title="Projetos - " />
      <TopBar />
      <ScrollTopButton />

      <Title>
        <h1>Meus Projetos</h1>
      </Title>

      {data.map((dataProject, index) => (
        <ProjectCard key={index} dataProject={dataProject} />
      ))}
    </Container>
  )
}

export default Projects
