import React from 'react'
import { Container, NavLink } from './styles'
import { TiArrowBack } from 'react-icons/ti'
import Head from '../../components/Head'
import TopBar from '../../components/TopBar'

const Error = () => {
  return (
    <Container>
      <TopBar />
      <Head title="Página não encontrada - " />

      <h1>404</h1>
      <h2>Página não encontrada!</h2>
      <h3>Esta página não existe.</h3>
      <NavLink to="/">
        <TiArrowBack style={{ fontSize: '1.2rem' }} />
        Voltar para a página inicial
      </NavLink>
    </Container>
  )
}

export default Error
