import React from 'react'
import Certificate from '../../components/CertificateSlide'
import Experience from '../../components/Experience'
import Formation from '../../components/Formation'
import ScrollTopButton from '../../components/ScrollTopButton'
import Technology from '../../components/Technology'
import {
  AcademicFormation,
  Certificates,
  Container,
  ContainerTitle,
  Description,
  Experiences,
  Informations,
  Technologies,
  Title,
} from './styles'

import TopBar from '../../components/TopBar'
import Head from '../../components/Head'

const About = () => {
  function ScrollTop() {
    window.scrollTo(0, 0)
    //window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  ScrollTop()

  return (
    <Container>
      <Head title="Sobre - " />
      <TopBar />
      <ScrollTopButton />

      <Informations>
        <Title>
          <h1>Sobre mim</h1>
        </Title>

        {/* Minha jornada como programador começou em 2017, onde fiz o curso de
        Técnico em Informática focado em Programação, onde tive contato com
        linguagens back-end, front-end, mobile e banco de dados. Me
        idenfiquei bastante com a parte do front-end, porém comecei a
        trabalhar como Full Stack(web e mobile). */}

        <Description data-aos="fade-right">
          <p className="description">
            Olá, meu nome é Bruno e sou apaixonado por Programação. Venho
            estudando tecnologias na área há cerca de 5 anos, com foco no
            desenvolvimento de aplicações Web e Mobile. Sigo buscando
            oportunidades para resolver problemas.
          </p>
          <br />
          <p>
            Minha jornada como programador começou em 2017, onde fiz o curso de
            Técnico em Informática focado em Programação, onde tive contato com
            linguagens back-end, front-end, mobile e banco de dados.
          </p>
          <br />
          <p>
            Atualmente estou aperfeiçoando meus conhecimentos em desenvolvimento
            Full Stack com as tecnologias ReactJS, NodeJS, MongoDB e React
            Native.
          </p>
        </Description>
      </Informations>

      <Technologies>
        <Title style={{ width: 'max-content', marginBottom: '30px' }}>
          <h1>Conhecimentos</h1>
        </Title>

        <Technology />
      </Technologies>

      <Certificates>
        <ContainerTitle>
          <Title style={{ width: 'max-content' }}>
            <h1>Certificados</h1>
          </Title>
        </ContainerTitle>

        <Certificate />
      </Certificates>

      <Experiences>
        <ContainerTitle>
          <Title style={{ width: 'max-content' }}>
            <h1>Experiência Profissional</h1>
          </Title>
        </ContainerTitle>

        <Experience />
      </Experiences>

      <AcademicFormation>
        <ContainerTitle>
          <Title style={{ width: 'max-content' }}>
            <h1>Formação</h1>
          </Title>
        </ContainerTitle>

        <Formation />
      </AcademicFormation>
    </Container>
  )
}

export default About
