import React, { useEffect } from 'react'

import { FaArrowUp } from 'react-icons/fa'

const ScrollTopButton = () => {
  let calcScrollValue = () => {
    let scrollProgress = document.getElementById('progress')
    if (!scrollProgress) return
    let pos = document.documentElement.scrollTop
    let calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    let scrollValue = Math.round((pos * 100) / calcHeight)

    if (pos > 100) {
      scrollProgress.style.display = 'grid'
    } else {
      scrollProgress.style.display = 'none'
    }

    scrollProgress.addEventListener('click', () => {
      document.documentElement.scrollTop = 0
    })

    scrollProgress.style.background = `conic-gradient(#ff2c5f ${scrollValue}%, #333 ${scrollValue}%)`
  }

  useEffect(() => {
    window.onscroll = calcScrollValue
    window.onload = calcScrollValue
  }, [])

  return (
    <div id="progress">
      <span id="progress-value">
        <FaArrowUp style={{ color: '#ff2c5f' }} />
      </span>
    </div>
  )
}

export default ScrollTopButton
