//projetos
// import NetflixMobile from '../assets/projetos/350w/react-native-netflix.png'
// import NetflixWeb from '../assets/projetos/350w/web-netflix.png'
// import WaiterAppWeb from '../assets/projetos/350w/web-waiter-app.png'
// import WaiterAppMobile from '../assets/projetos/350w/mobile-waiter-app.png'
// import DevLink from '../assets/projetos/350w/dev-link.png'
// import Instagram from '../assets/projetos/350w/tela-instagram.png'
// import Financas from '../assets/projetos/350w/financas.png'
// import PortfolioAntigo from '../assets/projetos/350w/port-antigo.png'
// import Dogs from '../assets/projetos/350w/dogs.png'
// import Movies from '../assets/projetos/350w/movies.png'
// import Ranek from '../assets/projetos/350w/ranek.png'
// import AnimaisFantasticos from '../assets/projetos/350w/animais-fantasticos.png'
// import Bikcraft2 from '../assets/projetos/350w/bikcraft-2.png'
// import PortfolioLobo from '../assets/projetos/350w/port-lobo.png'
// import Bikcraft1 from '../assets/projetos/350w/bikcraft-1.png'
// import Wildbeast from '../assets/projetos/350w/wildbeast.png'
// import Burger from '../assets/projetos/350w/burger.png'
// import FlexBlog from '../assets/projetos/350w/flexblog.png'
// import Hotel from '../assets/projetos/350w/hotel.png'

//detalhes do projeto
//netflix mobile
// import NetflixLogin from '../assets/projetos/detalhesProjetos/clone-netflix-mobile/login.png'
// import NetflixHome1 from '../assets/projetos/detalhesProjetos/clone-netflix-mobile/home_1.png'
// import NetflixHome2 from '../assets/projetos/detalhesProjetos/clone-netflix-mobile/home_2.png'
// import NetflixDetalhes1 from '../assets/projetos/detalhesProjetos/clone-netflix-mobile/detalhes_1.png'
// import NetflixDetalhes2 from '../assets/projetos/detalhesProjetos/clone-netflix-mobile/detalhes_2.png'
// import NetflixTabMenu from '../assets/projetos/detalhesProjetos/clone-netflix-mobile/tab_menu.png'

// //netflix web
// import NetflixHome from '../assets/projetos/detalhesProjetos/clone-netflix-web/home.png'
// import NetflixModal from '../assets/projetos/detalhesProjetos/clone-netflix-web/modal.png'

// //waiter app web
// import WaiterHome from '../assets/projetos/detalhesProjetos/waiter-app-web/home.png'
// import WaiterModal from '../assets/projetos/detalhesProjetos/waiter-app-web/modal.png'
// import WaiterStatus from '../assets/projetos/detalhesProjetos/waiter-app-web/status.png'

// //waiter app mobile
// import WaiterHomeMobile from '../assets/projetos/detalhesProjetos/waiter-app-mobile/tela-inicial.png'
// import WaiterProduto from '../assets/projetos/detalhesProjetos/waiter-app-mobile/produto.png'
// import WaiterMesa from '../assets/projetos/detalhesProjetos/waiter-app-mobile/mesa.png'
// import WaiterCarrinho from '../assets/projetos/detalhesProjetos/waiter-app-mobile/carrinho.png'
// import WaiterPedidoConfirmado from '../assets/projetos/detalhesProjetos/waiter-app-mobile/pedido-confirmado.png'

// //dev link
// import devLink from '../assets/projetos/detalhesProjetos/dev-link/meus-links.png'
// import devLinkLogin from '../assets/projetos/detalhesProjetos/dev-link/login-e-mensagem.png'
// import devLinkCadastar from '../assets/projetos/detalhesProjetos/dev-link/cadastrar-e-deletar.png'
// import devLinkEditar from '../assets/projetos/detalhesProjetos/dev-link/editar-redes-sociais.png'

// //login-instagram
// import LoginInstagram from '../assets/projetos/detalhesProjetos/login-instagram/login-instagram.png'

// //finanças
// import FinancasDetalhes from '../assets/projetos/detalhesProjetos/financas/financas.png'

// //portfolio antigo
// import PortfolioAntigoDetalhes from '../assets/projetos/detalhesProjetos/portfolio-antigo/port.png'

// //movies
// import MoviesHome from '../assets/projetos/detalhesProjetos/movies/home.png'
// import MoviesDetalhes from '../assets/projetos/detalhesProjetos/movies/detalhes.png'

// //dogs
// import DogsHome from '../assets/projetos/detalhesProjetos/dogs/home.png'
// import DogsLogin from '../assets/projetos/detalhesProjetos/dogs/login.png'
// import DogsRecuperar from '../assets/projetos/detalhesProjetos/dogs/recuperar-senha.png'
// import DogsCadastrar from '../assets/projetos/detalhesProjetos/dogs/cadastrar.png'
// import DogsConta from '../assets/projetos/detalhesProjetos/dogs/minha-conta.png'
// import DogsPostar from '../assets/projetos/detalhesProjetos/dogs/postar-foto.png'
// import DogsContaFoto from '../assets/projetos/detalhesProjetos/dogs/minha-conta-post.png'
// import DogsModal from '../assets/projetos/detalhesProjetos/dogs/modal.png'
// import DogsEstatisticas from '../assets/projetos/detalhesProjetos/dogs/estastisticas.png'
// import DogsHomeFoto from '../assets/projetos/detalhesProjetos/dogs/home-foto.png'

// //ranek
// import RanekDetalhes from '../assets/projetos/detalhesProjetos/ranek/ranek.png'

// //animais fantasticos
// import AnimaisFantasticosDetalhes from '../assets/projetos/detalhesProjetos/animais-fantasticos/animais-fantasticos.png'

// //bikcraft2
// import Bikcraft2Home from '../assets/projetos/detalhesProjetos/bikcraft/bikcraft2.png'
// import Bikcraft2Bicicletas from '../assets/projetos/detalhesProjetos/bikcraft/bicicletas.png'
// import Bikcraft2Bicicleta from '../assets/projetos/detalhesProjetos/bikcraft/bicicleta.png'
// import Bikcraft2Seguro from '../assets/projetos/detalhesProjetos/bikcraft/seguros.png'
// import Bikcraft2Orcamento from '../assets/projetos/detalhesProjetos/bikcraft/orcamento.png'
// import Bikcraft2Contato from '../assets/projetos/detalhesProjetos/bikcraft/contato.png'

// //portfolio lobo
// import PortfolioLoboHome from '../assets/projetos/detalhesProjetos/portfolio-lobo/port-lobo.png'

// //bikcraft1
// import Bikcraft1Home from '../assets/projetos/detalhesProjetos/bikcraft/bikcraft1.png'

// //wildbeast
// import WildbeastHome from '../assets/projetos/detalhesProjetos/wildbeast/wildbeast.png'

// //burger
// import BurgerHome from '../assets/projetos/detalhesProjetos/burger/burger.png'

// //flexblog
// import FlexBlogHome from '../assets/projetos/detalhesProjetos/flexblog/flexblog.png'

// //hotel paraiso
// import HotelHome from '../assets/projetos/detalhesProjetos/hotel-paraiso/hotel.png'

export const data = [
  {
    tipo: 'web',
    titulo: 'Sistema de Chamados - MERN',
    tituloURL: 'sistema-chamados',
    capa:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/bccb7004-9c29-458b-b8df-a703d1354ef2',
    descricao:
      'Projeto pessoal, onde o objetivo principal do projeto é criar uma solução simples e eficaz para a gestão de solicitações, dúvidas e problemas.',
    descricaoCard:
      'Projeto pessoal, onde o objetivo principal do projeto é criar uma solução simples e eficaz para a gestão de solicitações...',
    tecnologias: 'ReactJS, NodeJS, MongoDB, Styled Components, Material UI',
    api: 'https://github.com/BrunoMonteiro25/api-chamados',
    repositorio: 'https://github.com/BrunoMonteiro25/chamados',
    online: 'https://sistemachamados.vercel.app/',
    dataCriacao: '17/08/2023',
    funcionalidades:
      'Cadastrar Usuários, Login e Logout, Autenticação de Usuários, Cadastrar Clientes, Editar Clientes, Excluir Clientes, Editar dados do usuário logado, Cadastrar Novo Chamado, Visualizar detalhes do chamado, Editar Chamado, Excluir Chamado, Validações (email, cnpj).',
    telaLogin:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/87bb016f-3e53-4e8e-b209-90f7a6002405',
    telaCadastroUsuarios:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/8f431684-1c5f-4859-90eb-db17d73caab4',
    telaHome:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/362ac74c-f63e-4fbd-a8b9-92033f014f94',
    telaNovoCliente:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/87f265b5-7426-4ee9-8c14-01e0ca7150f8',
    telaEditarCliente:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/9b9ec450-0873-44f9-8320-92ecbf580ec0',
    telaExcluirCliente:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/3a78c93c-878d-4894-9c63-4ad00a2a328a',
    telaConfigConta:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/f9cebfb6-f503-4333-8976-feaf5ce9bda8',
    telaNovoChamado:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/693e68f7-9dcf-4de5-ab06-abc7c729ba49',
    telaChamadosCadastrados:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/d75411e7-ea29-416e-964a-4704b1faadf0',
    telaDetalhesChamados:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/9b3ff79d-93e2-4045-ada7-6b74364b803f',
    telaEditarChamado:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/b4a11000-a7d3-4ecb-9191-9fbddf2b3044',
    telaExcluirChamado:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/182944a5-1589-4070-81bc-0af71b87a6f7',
  },
  {
    tipo: 'mobile',
    titulo: 'Receita Fácil - React Native',
    tituloURL: 'receitas-mobile',
    capa:
      'https://github.com/BrunoMonteiro25/chamados/assets/98993736/d6596468-ff41-49e2-a190-fd6d348d1aa4',
    descricao:
      'Aplicativo de receitas feito durante o evento de React Native do canal do youtube (Sujeito Programador).',
    descricaoCard:
      'Aplicativo de receitas feito durante o evento de React Native do canal do youtube (Sujeito Programador).',
    tecnologias: 'React Native e JSON Server',
    api: '',
    repositorio: 'https://github.com/BrunoMonteiro25/receitas',
    online: '',
    dataCriacao: '15/06/2023',
    funcionalidades:
      'Lista de receitas, Detalhes da receita, Adicionar favoritos, Remover favoritos, Pesquisar por nome da receita',
    telaHome:
      'https://github.com/BrunoMonteiro25/receitas/assets/98993736/485f046c-8f89-4d5a-a10f-78f08a6d0460',
    telaDetalhesReceita:
      'https://github.com/BrunoMonteiro25/receitas/assets/98993736/2e06dd42-075f-4c43-b94f-1f6a917efd64',
    telaVideoReceita:
      'https://github.com/BrunoMonteiro25/receitas/assets/98993736/e877e408-b7df-4c4d-87f5-d1c6b2ccb4f6',
    telaFavoritosVazia:
      'https://github.com/BrunoMonteiro25/receitas/assets/98993736/354f71c1-3ede-44c3-ba89-2973af01cc4c',
    telaFavoritos:
      'https://github.com/BrunoMonteiro25/receitas/assets/98993736/45d1761d-4ac2-416c-9e52-d9249a1d17c6',
    telaPesquisa:
      'https://github.com/BrunoMonteiro25/receitas/assets/98993736/204522d3-8a6c-4a93-8d0e-992e037d785e',
  },
  {
    tipo: 'mobile',
    titulo: 'Netflix - React Native',
    tituloURL: 'netflix-mobile',
    capa:
      'https://user-images.githubusercontent.com/98993736/213900866-366488a7-2a56-4100-bd9e-137a4e75780b.png',
    descricao:
      'Aplicativo clone da Netflix feito durante o curso de React Native do canal do youtube (O irmão mais velho).',
    descricaoCard:
      'Aplicativo clone da Netflix feito durante o curso de React Native do canal do youtube (O irmão mais velho).',
    tecnologias: 'React Native',
    api: 'https://github.com/BrunoMonteiro25/api-clone-netlix',
    repositorio:
      'https://github.com/BrunoMonteiro25/react-native-clone-netflix',
    online: '',
    dataCriacao: '16/12/2022',
    funcionalidades:
      'Autenticação de Usuário e Senha, Listagem de filmes e séries que estão cadastrados na API, Banner com filmes e séries aleatórios ao recarregar a pagina inicial, Rotas com React Navigation, Página de Detalhes do filme ou da série selecionada, Filmes semelhantes.',
    telaLogin:
      'https://user-images.githubusercontent.com/98993736/213901874-be53fdc1-8d52-40c5-a6fa-df7a8ecfda66.png',
    telaHome1:
      'https://user-images.githubusercontent.com/98993736/213901876-9043d813-1a19-4a76-aa2d-8837dd771fa9.png',
    telaHome2:
      'https://user-images.githubusercontent.com/98993736/213901878-14c4f19a-5c17-4f4c-917e-97e12c11f178.png',
    telaDetalhes1:
      'https://user-images.githubusercontent.com/98993736/213901880-6bafd0ed-490a-4390-a7a0-36a96228bb46.png',
    telaDetalhes2:
      'https://user-images.githubusercontent.com/98993736/213901881-c19ef51d-3e16-4df4-b8f9-8c0c8401051d.png',
    telaTabMenu:
      'https://user-images.githubusercontent.com/98993736/213901883-1acb2a82-3e4a-4dac-8c38-d20bde93d8d4.png',
  },
  {
    tipo: 'web',
    titulo: 'Netflix - ReactJS',
    tituloURL: 'netflix-web',
    capa:
      'https://user-images.githubusercontent.com/98993736/213900906-91c5d94e-ef40-4ac6-af53-5d760e3f7039.png',
    descricao:
      'Projeto próprio clone da Netflix, nele consumi a API de filmes The Movie DB.',
    descricaoCard:
      'Projeto próprio clone da Netflix, nele consumi a API de filmes The Movie DB.',
    tecnologias: 'HTML5, CSS3, JavaScript, ReactJS, Styled Components',
    api: 'https://developers.themoviedb.org/3/movies/get-movie-details',
    repositorio: 'https://github.com/BrunoMonteiro25/Clone-Netflix',
    online: '',
    dataCriacao: '25/11/2022',
    funcionalidades:
      'Consumo da API de Filmes The Movie DB (tmdb), Listagem dos filmes por categoria, Modal de Detalhes do filme.',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213901923-b335c0be-f237-435f-8ee0-0fa835f20b58.png',
    telaModal:
      'https://user-images.githubusercontent.com/98993736/213901924-2c7115b6-993a-48c1-aacf-a5d9678cb009.png',
  },
  {
    tipo: 'web',
    titulo: 'Waiter App - ReactJS',
    tituloURL: 'waiter-app-web',
    capa:
      'https://user-images.githubusercontent.com/98993736/213900940-49e5d486-540d-4777-bcb0-0c097912ea30.png',
    descricao:
      'Sistema web para restaurantes. Nele é possível visualizar, alterar e cancelar os pedidos que foram gerados a partir da aplicação Mobile.',
    descricaoCard:
      'Sistema web para restaurantes. Nele é possível visualizar, alterar e cancelar os pedidos que foram gerados a partir da aplicação Mobile.',
    tecnologias: 'ReactJS, TypeScript, Styled Components',
    api: 'https://github.com/BrunoMonteiro25/api-waiterApp',
    repositorio: 'https://github.com/BrunoMonteiro25/react-waiterApp',
    online: '',
    dataCriacao: '21/11/2022',
    funcionalidades:
      'Listar todos os pedidos que foram criados na aplicação Mobile, Modal com os detalhes do pedido, Alterar status do pedido, Cancelar pedido.',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213901932-0cda9fda-1f0a-453d-ac3b-2e7d1860904c.png',
    telaModal:
      'https://user-images.githubusercontent.com/98993736/213901934-4b23d224-e35c-4acb-bc7d-5d61b8c3db8a.png',
    telaStatus:
      'https://user-images.githubusercontent.com/98993736/213901935-981bba2f-70ea-443e-a930-a800ab6a4fc5.png',
  },
  {
    tipo: 'mobile',
    titulo: 'Waiter App - React Native',
    tituloURL: 'waiter-app-mobile',
    capa:
      'https://user-images.githubusercontent.com/98993736/213900945-0a2ce324-5dbd-40e1-b465-42aa76c1c005.png',
    descricao:
      'Aplicativo mobile para restaurantes. Nele é possível realizar pedidos que vão para o sistema web.',
    descricaoCard:
      'Aplicativo mobile para restaurantes. Nele é possível realizar pedidos que vão para o sistema web.',
    tecnologias: 'React Native, TypeScript, Styled Components',
    api: 'https://github.com/BrunoMonteiro25/api-waiterApp',
    repositorio: 'https://github.com/BrunoMonteiro25/react-native-waiterApp',
    online: '',
    dataCriacao: '21/11/2022',
    funcionalidades:
      'Listar todos os produtos que estão cadastrados na API, Filtrar produtos pela categoria, Criar novo pedido, Cancelar pedido, Carrinho de compras, Confirmar pedido.',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213901975-ae6a9a63-dbea-49a5-9760-18e28c3e333e.png',
    telaProduto:
      'https://user-images.githubusercontent.com/98993736/213901979-47bf4036-720c-4d5b-8f48-430608b7d74c.png',
    telaMesa:
      'https://user-images.githubusercontent.com/98993736/213901982-8af82ba0-f434-4e0e-a390-41b67a950c61.png',
    telaCarrinho:
      'https://user-images.githubusercontent.com/98993736/213901984-3b0078d6-61d6-44da-9753-c263814f0477.png',
    telaPedido:
      'https://user-images.githubusercontent.com/98993736/213901985-f07ca09c-8a5b-4f6d-b543-12239f80ac9b.png',
  },
  {
    tipo: 'web',
    titulo: 'Dev Link',
    tituloURL: 'dev-link',
    capa:
      'https://user-images.githubusercontent.com/98993736/213900950-e771fab7-3f79-4e58-9ce9-2b5e9903d752.png',
    descricao:
      'Projeto de Links pessoais, que desenvolvi durante o evento (Semana React) do canal do youtube (Sujeito Programador).',
    descricaoCard:
      'Projeto de Links pessoais, que desenvolvi durante o evento (Semana React) do canal do youtube (Sujeito Programador).',
    tecnologias: 'HTML5, CSS3, JavaScript, ReactJS, Firebase',
    api: '',
    repositorio: 'https://github.com/BrunoMonteiro25/devlink',
    online: '',
    dataCriacao: '11/11/2022',
    funcionalidades:
      'Autenticação de usuário, Login e Logout, Cadastrar Links, Listagem dos Links, Deletar Links, Editar redes sociais, Rotas com React Router Dom, Banco de Dados e autenticação feita com Firebase.',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213902075-9cd1de7b-3536-4a98-9cc2-c37c653d535b.png',
    telaLogin:
      'https://user-images.githubusercontent.com/98993736/213902078-4c20be3c-39c6-45b4-9713-46ad1a07aeef.png',
    telaCadastrar:
      'https://user-images.githubusercontent.com/98993736/213902079-60798dc5-ad3d-496e-b2d3-847e7d18adb8.png',
    telaEditar:
      'https://user-images.githubusercontent.com/98993736/213902081-be62d20a-ebd9-40c1-9fc2-0c9ba4dd43fb.png',
  },
  {
    tipo: 'mobile',
    titulo: 'Tela de Login Instagram',
    tituloURL: 'tela-instagram',
    capa:
      'https://user-images.githubusercontent.com/98993736/213900956-16244597-2f5d-4c54-b98d-679c6621d22b.png',
    descricao: 'Tela que fiz enquanto estava aprendendo React Native.',
    descricaoCard: 'Tela que fiz enquanto estava aprendendo React Native.',
    tecnologias: 'React Native',
    api: '',
    repositorio: 'https://github.com/BrunoMonteiro25/Tela-Login-Instagram',
    online: '',
    dataCriacao: '03/11/2022',
    funcionalidades: '',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213902116-ae8868e0-ba66-4ba5-9c9d-8201c86963c4.png',
  },
  {
    tipo: 'mobile',
    titulo: 'Finanças',
    tituloURL: 'financas',
    capa:
      'https://user-images.githubusercontent.com/98993736/213900959-e971f351-bd41-4621-a84e-b3d1134a843a.png',
    descricao: 'Primeira tela que fiz com React Native.',
    descricaoCard: 'Primeira tela que fiz com React Native.',
    tecnologias: 'React Native',
    api: '',
    repositorio: 'https://github.com/BrunoMonteiro25/Financas',
    online: '',
    dataCriacao: '02/11/2022',
    funcionalidades: '',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213902132-ccee50af-101d-4676-9640-d5152e90fa33.png',
  },
  {
    tipo: 'web',
    titulo: 'Portfólio Antigo',
    tituloURL: 'portfolio-antigo',
    capa:
      'https://user-images.githubusercontent.com/98993736/213900960-2b817107-1bf7-485d-89c1-6c7fbcc1cc7c.png',
    descricao:
      'Meu primeiro portfólio pessoal e também projeto próprio que desenvolvi depois de ter feito meu primeiro curso de ReactJS.',
    descricaoCard:
      'Meu primeiro portfólio pessoal e também projeto próprio que desenvolvi depois de ter feito meu primeiro curso de ReactJS.',
    tecnologias: 'HTML5, CSS3, JavaScript, ReactJS, Styled Components',
    api: '',
    repositorio: 'https://github.com/BrunoMonteiro25/Meu-Portfolio',
    online: 'https://meu-portfolio-beryl.vercel.app/',
    dataCriacao: '15/09/2022',
    funcionalidades:
      'Download do currículo, Links externos(Linkedin, Github, Repositório do Projeto, Projeto Online).',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213902142-07f319f0-1069-40b4-816f-78d321af9ee0.png',
  },
  {
    tipo: 'web',
    titulo: 'Movies',
    tituloURL: 'movies',
    capa:
      'https://user-images.githubusercontent.com/98993736/213901005-c800b0a3-9a8e-4a0a-afff-790d0e762586.png',
    descricao:
      'Mini projeto de filmes consumindo a API The Movie DB. Desenvolvido durante o curso de ReactJS do canal do youtube (Girl Coding).',
    descricaoCard:
      'Mini projeto de filmes consumindo a API The Movie DB. Desenvolvido durante o curso de ReactJS do canal do youtube (Girl Coding).',
    tecnologias: 'HTML5, CSS3, JavaScript, ReactJS, Styled Components',
    api: 'https://developers.themoviedb.org/3/movies/get-movie-details',
    repositorio: 'https://github.com/BrunoMonteiro25/api-filmes',
    online: 'https://api-filmes-two.vercel.app/',
    dataCriacao: '11/09/2022',
    funcionalidades:
      'Consumo da API de Filmes The Movie DB, Listagem dos filmes lançamentos, Página de Detalhes do filme, Rotas com react-router-dom.',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213902217-28d5bbce-7c29-4b3b-bda9-f1e99db1cdc9.png',
    telaDetalhes:
      'https://user-images.githubusercontent.com/98993736/213902219-6898828a-ea8b-4f70-9c41-e6af92cb0885.png',
  },
  {
    tipo: 'web',
    titulo: 'Dogs - Rede Social para Cachorros',
    tituloURL: 'dogs',
    capa:
      'https://user-images.githubusercontent.com/98993736/213901007-4c2f242c-a53b-44ef-b5bc-2ed632134715.png',
    descricao:
      'Projeto de rede social tipo instagram, mas para cachorros. Desenvolvido no curso de ReactJS da Origamid.',
    descricaoCard:
      'Projeto de rede social tipo instagram, mas para cachorros. Desenvolvido no curso de ReactJS da Origamid.',
    tecnologias: 'HTML5, CSS3, JavaScript, ReactJS',
    api: '',
    repositorio: 'https://github.com/BrunoMonteiro25/Dogs',
    online: 'https://dogs-lyart.vercel.app/',
    dataCriacao: '08/09/2022',
    funcionalidades:
      'Cadastro de Usuário, Recuperar Senha, Trocar Senha, Logar (validação nos campos de usuário e senha), Feed de fotos consumido da API Dogs, Scroll infinito no Feed, Postar Foto, Feed interno para cada conta (exibe apenas as fotos postadas de determinado usuário), Estatísticas: exibe dois gráficos para o total de acessos nas fotos, Postar comentários nas fotos, Deletar Fotos (deleta apenas as fotos daquele determinado usuário), Visualizar perfil de outros usuários, Deslogar.',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213902258-ad609273-97ed-46c1-a2cd-e1b77c436da9.png',
    telaLogin:
      'https://user-images.githubusercontent.com/98993736/213902260-f200dec5-6714-453d-86b0-b73a6c563818.png',
    telaRecuperar:
      'https://user-images.githubusercontent.com/98993736/213902263-38330ef0-eb4c-43a8-858c-bad30d0f6771.png',
    telaCadastrar:
      'https://user-images.githubusercontent.com/98993736/213902265-8a7e81f5-d7f7-44c0-9c7e-c0e4a4e846fd.png',
    telaConta:
      'https://user-images.githubusercontent.com/98993736/213902274-e890b3bd-c573-49b3-8ed5-240c950d2bcd.png',
    telaPostar:
      'https://user-images.githubusercontent.com/98993736/213902281-c096a6f5-6e2b-41d9-8cce-1f4651a847cc.png',
    telaContaPost:
      'https://user-images.githubusercontent.com/98993736/213902284-f8f5a26a-9740-4cbe-babb-383fa5363314.png',
    telaModal:
      'https://user-images.githubusercontent.com/98993736/213902291-231f1d7e-8707-465b-bd1f-07efbc90454f.png',
    telaEstatisticas:
      'https://user-images.githubusercontent.com/98993736/213902296-0e8bf277-3af0-40c9-bf85-a4812a2ec6ee.png',
    telaHomeFoto:
      'https://user-images.githubusercontent.com/98993736/213902304-04c2646f-1d9c-4219-befe-cadb7b53a772.png',
  },
  {
    tipo: 'web',
    titulo: 'Ranek',
    tituloURL: 'ranek',
    capa:
      'https://user-images.githubusercontent.com/98993736/213901009-097e3f64-dd6f-4ab1-abcc-8d455f34a6a5.png',
    descricao:
      'Lista de Produtos consumida de uma api. Desenvolvido no curso de ReactJS da Origamid.',
    descricaoCard:
      'Lista de Produtos consumida de uma api. Desenvolvido no curso de ReactJS da Origamid.',
    tecnologias: 'HTML5, CSS3, JavaScript, ReactJS',
    api: '',
    repositorio: 'https://github.com/BrunoMonteiro25/Ranek',
    online: '',
    dataCriacao: '16/08/2022',
    funcionalidades:
      'Consumo da API de Produtos, Listagem dos produtos, Rotas com react-router-dom.',
    telaHome:
      'https://user-images.githubusercontent.com/98993736/213902428-2f2197ef-351e-4cfc-a680-5f273a27db56.png',
  },
  // {
  //   tipo: 'web',
  //   titulo: 'Animais Fantásticos',
  //   tituloURL: 'animais-fantasticos',
  //   capa:
  //     'https://user-images.githubusercontent.com/98993736/213901015-e19c23f9-e26c-4bee-8454-ffeb9bdcef19.png',
  //   descricao: 'Projeto feito no curso de JavaScript ES6+ da Origamid.',
  //   tecnologias: 'HTML5, CSS3 (Flexbox + Grid), JavaScrip',
  //   api: '',
  //   repositorio: 'https://github.com/BrunoMonteiro25/Animais-Fantasticos',
  //   online: '',
  //   dataCriacao: '28/06/2022',
  //   funcionalidades: '',
  //   telaHome:
  //     'https://user-images.githubusercontent.com/98993736/213902430-fa00a44f-c074-4318-a426-339bdd253bb5.png',
  // },
  // {
  //   tipo: 'web',
  //   titulo: 'Bikcraft 2.0',
  //   tituloURL: 'bikcraft-2',
  //   capa:
  //     'https://user-images.githubusercontent.com/98993736/213901017-46e4e6a7-88cf-4a30-81a7-1989a3a9f389.png',
  //   descricao:
  //     'Projeto final do curso de CSS (Flexbox, Grid) e Javascript básico da Origamid.',
  //   tecnologias: 'HTML5, CSS3 (Flexbox + Grid), Javascript.',
  //   api: '',
  //   repositorio: 'https://github.com/BrunoMonteiro25/Bikcraft-2.0',
  //   online: '',
  //   dataCriacao: '29/03/2022',
  //   funcionalidades: '',
  //   telaHome:
  //     'https://user-images.githubusercontent.com/98993736/213902464-6292c253-bda4-4d09-9c54-0a527542b72b.png',
  //   telaBicicletas:
  //     'https://user-images.githubusercontent.com/98993736/213902469-9f53127f-7ffd-4fe2-b804-bdbad3e2f432.png',
  //   telaBicicleta:
  //     'https://user-images.githubusercontent.com/98993736/213902470-4d12168f-451c-418c-b2e5-dc0c05d9627f.png',
  //   telaSeguro:
  //     'https://user-images.githubusercontent.com/98993736/213902471-e2de548b-b8ec-4e92-8eaf-07d34306c6f3.png',
  //   telaOrcamento:
  //     'https://user-images.githubusercontent.com/98993736/213902472-3dcd2578-cda1-45cc-8e99-d119b6ee5990.png',
  //   telaContato:
  //     'https://user-images.githubusercontent.com/98993736/213902474-b49e5ecd-6ab1-4a93-b6fb-06cdccb675e6.png',
  // },
  // {
  //   tipo: 'web',
  //   titulo: 'Portfólio',
  //   tituloURL: 'portfolio',
  //   capa:
  //     'https://user-images.githubusercontent.com/98993736/213901022-9967b30c-7b32-4329-af0b-3991a1d6a9a7.png',
  //   descricao:
  //     'Segundo projeto do curso de CSS (Flexbox, Grid) e Javascript básico da Origamid.',
  //   tecnologias: 'HTML5, CSS3 (Flexbox + Grid)',
  //   api: '',
  //   repositorio: 'https://github.com/BrunoMonteiro25/Portfolio',
  //   online: '',
  //   dataCriacao: '25/03/2022',
  //   funcionalidades: '',
  //   telaHome:
  //     'https://user-images.githubusercontent.com/98993736/213902577-6b14c432-8cd6-49dc-87f6-acb63748cceb.png',
  // },
  // {
  //   tipo: 'web',
  //   titulo: 'Bikcraft',
  //   tituloURL: 'bikcraft',
  //   capa:
  //     'https://user-images.githubusercontent.com/98993736/213901024-f777737e-e37e-475c-b134-44cb890ed3ae.png',
  //   descricao:
  //     'Primeiro projeto do curso de CSS (Flexbox, Grid) e Javascript básico da Origamid.',
  //   tecnologias: 'HTML5, CSS3 (Flexbox + Grid)',
  //   api: '',
  //   repositorio: 'https://github.com/BrunoMonteiro25/Bikcraft-1.0',
  //   online: '',
  //   dataCriacao: '20/03/2022',
  //   funcionalidades: '',
  //   telaHome:
  //     'https://user-images.githubusercontent.com/98993736/213902571-79318877-dcb7-4771-9b62-b9dd1a315573.png',
  // },
  // {
  //   tipo: 'web',
  //   titulo: 'Wildbeast',
  //   tituloURL: 'wildbeast',
  //   capa:
  //     'https://user-images.githubusercontent.com/98993736/213901074-710b7985-b147-4f4a-8031-087da515592f.png',
  //   descricao: 'Projeto que fiz no curso de CSS Grid da Origamid.',
  //   tecnologias: 'HTML5, CSS3 (Grid Layout)',
  //   api: '',
  //   repositorio: 'https://github.com/BrunoMonteiro25/Wildbeast',
  //   online: '',
  //   dataCriacao: '02/03/2022',
  //   funcionalidades: '',
  //   telaHome:
  //     'https://user-images.githubusercontent.com/98993736/213902586-62f01d32-3c67-4730-88b2-d7ce156bb931.png',
  // },
  // {
  //   tipo: 'web',
  //   titulo: 'Burger',
  //   tituloURL: 'burger',
  //   capa:
  //     'https://user-images.githubusercontent.com/98993736/213901079-b1e1ca70-bfe9-4229-808f-22f7834c26ef.png',
  //   descricao:
  //     'Projeto próprio utilizando o que aprendi no curso de CSS Flexbox.',
  //   tecnologias: 'HTML5, CSS3 (Flexbox)',
  //   api: '',
  //   repositorio: 'https://github.com/BrunoMonteiro25/Projeto-Burger',
  //   online: '',
  //   dataCriacao: '19/02/2022',
  //   funcionalidades: '',
  //   telaHome:
  //     'https://user-images.githubusercontent.com/98993736/213902592-e4dfcfec-844a-436e-9a11-1ce068fc5b4b.png',
  // },
  // {
  //   tipo: 'web',
  //   titulo: 'Flexblog',
  //   tituloURL: 'flexblog',
  //   capa:
  //     'https://user-images.githubusercontent.com/98993736/213901081-ec176544-a6f5-408f-a69a-92e1085fb85d.png',
  //   descricao: 'Projeto que fiz no curso de CSS Flexbox da Origamid.',
  //   tecnologias: 'HTML5, CSS3 (Flexbox)',
  //   api: '',
  //   repositorio: 'https://github.com/BrunoMonteiro25/FlexBlog',
  //   online: '',
  //   dataCriacao: '04/02/2022',
  //   funcionalidades: '',
  //   telaHome:
  //     'https://user-images.githubusercontent.com/98993736/213902594-62caf6fa-ac88-42f9-86aa-86cec098a01c.png',
  // },
  // {
  //   tipo: 'web',
  //   titulo: 'Hotel Paraíso',
  //   tituloURL: 'hotel-paraiso',
  //   capa:
  //     'https://user-images.githubusercontent.com/98993736/213901088-87515d3a-1ebc-4968-93e3-e11501f0f166.png',
  //   descricao: 'Projeto do primeiro curso de CSS Flexbox que fiz.',
  //   tecnologias: 'HTML5, CSS3 (Flexbox)',
  //   api: '',
  //   repositorio: 'https://github.com/BrunoMonteiro25/Hotel-Paraiso',
  //   online: '',
  //   dataCriacao: '03/02/2022',
  //   funcionalidades: '',
  //   telaHome:
  //     'https://user-images.githubusercontent.com/98993736/213902598-9c9d85d1-c07c-4761-8b4b-bb95686eac7a.png',
  // },
]
