import React, { useState } from 'react'
import { Container, Informations, Image, Buttons, NavLink } from './styles'
import CurriculoLinkedin from '../../curriculo/curriculo_linkedin.pdf'
import { BsDownload } from 'react-icons/bs'
import { GiTalk } from 'react-icons/gi'
import Button from '../../components/Button'
import TopBar from '../../components/TopBar'
import Head from '../../components/Head'

// import Imagem from '../../assets/img.png'

const imgHome =
  'https://user-images.githubusercontent.com/98993736/213896133-ba592a51-8e67-42a8-b77b-9f2c4921a059.png'

const Home = () => {
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  function ScrollTop() {
    window.scrollTo(0, 0)
  }
  ScrollTop()

  const widthScreen =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth

  return (
    <Container>
      <Head title="" />
      <TopBar />

      {widthScreen <= 768 ? (
        <Informations data-aos="fade-down">
          <h1>
            Olá, eu sou
            <br />
            Bruno Monteiro
          </h1>
          <span>Desenvolvedor Full Stack</span>
          <Buttons>
            <NavLink to="/contato">
              <GiTalk style={{ fontSize: '1.2rem' }} />
              Fale Comigo
            </NavLink>
            <Button
              href={CurriculoLinkedin}
              download="curriculo_linkedin.pdf"
              type="appication/pdf"
              style={{ backgroundColor: isHover ? '#333' : '#222' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <BsDownload style={{ fontSize: '1.2rem' }} />
              Curriculo
            </Button>
          </Buttons>
        </Informations>
      ) : (
        <Informations data-aos="fade-up">
          <h1>
            Olá, eu sou
            <br />
            Bruno Monteiro
          </h1>
          <span>Desenvolvedor Full Stack</span>
          <Buttons>
            <NavLink to="/contato">
              <GiTalk style={{ fontSize: '1.2rem' }} />
              Fale Comigo
            </NavLink>
            <Button
              href={CurriculoLinkedin}
              download="curriculo_linkedin.pdf"
              type="appication/pdf"
              style={{ backgroundColor: isHover ? '#333' : '#222' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <BsDownload style={{ fontSize: '1.2rem' }} />
              Curriculo
            </Button>
          </Buttons>
        </Informations>
      )}

      {widthScreen <= 768 ? (
        <Image data-aos="fade-down">
          <img src={imgHome} alt="imagem" className="img-mobile" />
        </Image>
      ) : (
        <Image data-aos="fade-down">
          <img src={imgHome} alt="imagem" className="img-mobile" />
        </Image>
      )}
    </Container>
  )
}

export default Home
