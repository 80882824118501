import React from 'react'
import { Container, Title, Institution, Formations } from './styles'

const Formation = () => {
  return (
    <Container data-aos="fade-up">
      <Formations>
        <Title>
          <h2>Histórico Acadêmico</h2>
        </Title>

        <Institution>
          <h3>UNINOVE - Universidade Nove de Julho</h3>
          <span>
            Análise e Desenvolvimento de Sistemas | ago de 2021 - Cursando
          </span>
        </Institution>

        <Institution>
          <h3>ITB - Instituto Técnico de Barueri Brasílio Flores de Azevedo</h3>
          <span>Técnico de Informática | fev de 2017 - jul de 2018</span>
          <p>
            <span className="tecs">Aprendizados: </span>
            HTML 5, CSS3, Javascript, C#(Windows Form e ASP.NET), Java(desktop e
            mobile), SQL Server
          </p>
        </Institution>
      </Formations>

      <Formations>
        <Title>
          <h2>Cursos</h2>
        </Title>

        <Institution>
          <h3 style={{ marginBottom: '10px' }}>Origamid</h3>
          <span>
            Plataforma de formação em desenvolvimento web, onde cursei Web
            Design completo, CSS (Flexbox e Grid Layout), Javascript completo
            ES6 e React Completo. A plataforma foi essencial para minha base no
            Front-end.
          </span>
        </Institution>

        <Institution>
          <h3 style={{ marginBottom: '10px' }}>
            Sujeito Programador - Fábrica de Aplicativos
          </h3>
          <span>
            Plataforma de formação em desenvolvimento web e mobile, onde cursei
            React Native.
          </span>
        </Institution>
      </Formations>
    </Container>
  )
}

export default Formation
