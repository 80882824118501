import React from 'react'

import {
  Buttons,
  ButtonStyled,
  Card,
  Container,
  Content,
  ImageContainer,
} from './styles'

import CurriculoLinkedin from '../../curriculo/curriculo_linkedin.pdf'
import { BsDownload } from 'react-icons/bs'
import { SiGithub, SiWhatsapp, SiLinkedin, SiGmail } from 'react-icons/si'

import TopBar from '../../components/TopBar'

// import Foto from '../../assets/foto2.png'
import Head from '../../components/Head'

const Contact = () => {
  function ScrollTop() {
    window.scrollTo(0, 0)
  }

  ScrollTop()

  const img =
    'https://user-images.githubusercontent.com/98993736/213976872-e1d62adf-822b-4522-9fc3-1afb1b704103.png'

  return (
    <Container>
      <Head title="Contato - " />
      <TopBar />

      <Card data-aos="fade-down">
        <ImageContainer>
          <img src={img} alt="Bruno Monteiro" />
        </ImageContainer>

        <Content>
          <h3>Bruno Monteiro</h3>
          <span>Desenvolvedor Full Stack</span>
        </Content>

        <Buttons>
          <ButtonStyled
            href={CurriculoLinkedin}
            download="curriculo_linkedin.pdf"
            type="appication/pdf"
          >
            <BsDownload className="icon" />
            Curriculo
          </ButtonStyled>

          <ButtonStyled href="https://wa.me/5511951301497" target="_blank">
            <SiWhatsapp className="icon" />
            Whatsapp
          </ButtonStyled>

          <ButtonStyled
            href="https://www.linkedin.com/in/bruno-monteiro-796430252/"
            target="_blank"
          >
            <SiLinkedin className="icon" />
            Linkedin
          </ButtonStyled>

          <ButtonStyled
            href="https://github.com/BrunoMonteiro25"
            target="_blank"
          >
            <SiGithub className="icon" />
            Github
          </ButtonStyled>

          <ButtonStyled
            href="mailto:bruno.monteiro.silva25@gmail.com"
            target="_blank"
          >
            <SiGmail className="icon" />
            Email
          </ButtonStyled>
        </Buttons>
      </Card>
    </Container>
  )
}

export default Contact
