import styled from 'styled-components'
import { secondaryColor } from '../../colors'

export const Container = styled.div`
  max-width: 70rem;
  margin: 140px auto 0 auto;
  /* border: 1px solid white; */
  height: max-content;
  /* margin-top: 50px; */
`

export const Informations = styled.div`
  height: 300px;
  width: 100%;
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.div`
  /* border: 1px solid white; */
  position: relative;
  text-align: center;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${secondaryColor};
    bottom: 0;
    left: 0;
  }

  h1 {
    font-size: 1.5rem;
    padding: 20px 0;
  }
`

export const Description = styled.div`
  /* border: 1px solid white; */
  max-width: 800px;
  padding-left: 10px;

  .description {
    margin-top: 40px;
    margin-bottom: 5px;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
  }
`

export const Technologies = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  /* border: 1px solid white; */
  height: max-content;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 593px) {
    margin-top: 150px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 190px;
  }

  @media screen and (max-width: 385px) {
    margin-top: 240px;
  }

  @media screen and (max-width: 348px) {
    margin-top: 280px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 300px;
  }
`

export const Certificates = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  /* border: 1px solid white; */
  height: max-content;
  margin: 70px 0;
  padding: 0 1rem;
`

export const ContainerTitle = styled.div`
  width: 100%;
  /* border: 1px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`

export const Experiences = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  /* border: 1px solid white; */
  height: max-content;
  margin: 120px 0 70px 0;
  padding: 0 1rem;
`

export const AcademicFormation = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  /* border: 1px solid white; */
  height: max-content;
  margin: 120px 0 80px 0;
  padding: 0 1rem;

  @media screen and (max-width: 396px) {
    margin-bottom: 0px !important;
  }
`
