import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import HeaderMobile from './components/HeaderMobile'
import About from './pages/About'
import Contact from './pages/Contact'
import Error from './pages/Error'
import Home from './pages/Home'
import Projects from './pages/Projects'
import ProjectDetail from './pages/ProjectDetail'

import { data } from './services/api'

import Aos from 'aos'
import 'aos/dist/aos.css'

const App = () => {
  const [menuIsVisible, setMenuIsVisible] = useState(false)

  useEffect(() => {
    Aos.init({ duration: 1500 })
  }, [])

  return (
    <>
      <Router>
        <HeaderMobile
          menuIsVisible={menuIsVisible}
          setMenuIsVisible={setMenuIsVisible}
        />
        <Header setMenuIsVisible={setMenuIsVisible} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre" element={<About />} />
          <Route path="/projetos" element={<Projects />} />
          <Route
            path="/projetos/:tipo/:titulo"
            element={<ProjectDetail data={data} />}
          />
          <Route path="/contato" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </>
  )
}

export default App
