import styled from 'styled-components'

import { secondaryColor, whiteColorText } from '../../colors'

export const Container = styled.div`
  height: max-content;
  /* border: 1px solid white; */
`

export const Company = styled.div`
  display: flex;
  /* border: 1px solid white; */
  align-items: center;
  gap: 1rem;
  max-width: max-content;

  img {
    max-width: 60px;
    border-radius: 4px;
  }
`

export const CompanyDetails = styled.div`
  /* border: 1px solid white; */

  h3 {
    font-size: 1.1rem;
    margin-bottom: 5px;
    color: ${whiteColorText};
  }

  span {
    font-size: 0.9rem;
    font-weight: 400;
    opacity: 0.9;
    color: ${whiteColorText};
  }

  p {
    font-size: 0.9rem;
    opacity: 0.7;
    margin-top: 5px;
    color: ${whiteColorText};
  }
`

export const Office = styled.div`
  /* border: 1px solid white; */
  height: max-content;
  max-width: 800px;
  margin: 30px 0 30px 77px;

  h4 {
    font-size: 1rem;
    margin-bottom: 5px;
    color: ${whiteColorText};
  }

  span {
    font-size: 0.9rem;
    font-weight: 400;
    opacity: 0.9;
  }

  .time {
    font-size: 0.9rem;
    opacity: 0.7;
    margin-top: 5px;
    color: ${whiteColorText};
  }

  .description {
    font-size: 1rem;
    margin-top: 15px;
  }

  .color-tec {
    color: ${secondaryColor};
    font-size: 1rem;
    font-weight: bold;
  }
`
