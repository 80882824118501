import React, { useEffect, useRef } from 'react'
import LoadingBar from 'react-top-loading-bar'

const TopBar = () => {
  const ref = useRef(null)

  useEffect(() => {
    ref.current.staticStart()
    setTimeout(() => {
      ref.current.complete()
    }, 1000)
  }, [])

  return <LoadingBar color="#ff2c5f" height="4px" ref={ref} />
}

export default TopBar
