import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

import {
  secondaryColor,
  headerCardColor,
  cardColor,
  whiteColorText,
  boxShadowColor,
} from '../../colors'

export const Card = styled.div`
  width: 350px;
  min-height: 415px;
  border-radius: 14px;
  background-color: ${cardColor};
  box-shadow: 0 5px 15px ${boxShadowColor};
  /* border: 1px solid white; */
  transition: transform 0.3s;
  padding-bottom: 15px;
  margin-top: 50px;
  position: relative;

  :hover {
    transform: translate3d(0, -10px, 0) !important;
    animation-duration: 0.3s !important;
    transition-duration: 0.3s !important;
    box-shadow: 0 0 3px ${secondaryColor};
  }

  @media screen and (max-width: 355px) {
    width: 300px;
    min-height: 430px !important;
  }
`

export const Content = styled.div`
  /* margin: 20px 20px 30px 20px; */
  margin-bottom: 30px;

  .title {
    /* border: 1px solid white; */
    width: 100%;
    background-color: ${headerCardColor};
  }

  .title h3 {
    padding: 15px 20px;
    font-size: 1.3rem;
    color: ${secondaryColor};
  }

  p {
    font-size: 1rem;
    color: ${whiteColorText};
    opacity: 0.8;
    font-weight: 300;
    line-height: 1.2;
    margin: 10px 20px 25px 20px;
    /* border: 1px solid white; */
  }
`

export const ImageContainer = styled.div`
  width: 350px;
  height: 200px;
  border-bottom: 1px solid ${secondaryColor};

  img {
    width: 350px;
    height: 100%;
    border-radius: 14px 14px 0 0;
  }

  @media screen and (max-width: 355px) {
    max-width: 300px;

    img {
      max-width: 300px;
    }
  }
`

export const NavLink = styled(Link)`
  position: absolute;
  bottom: 20px;
  width: 310px;
  margin-right: 20px !important;
  margin-left: 20px !important ;
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  color: ${whiteColorText};
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid ${secondaryColor};
  letter-spacing: 1px;

  &:hover {
    background-color: ${secondaryColor};
  }

  @media screen and (max-width: 355px) {
    width: 260px;
  }
`
