import styled from 'styled-components'

import { whiteColorText } from '../../colors'

export const FooterContainer = styled.div`
  padding: 1rem 0;
  text-align: center;
  font-size: 0.9rem;
  color: ${whiteColorText};
  position: absolute;
  width: 100%;

  @media screen and (max-width: 395px) {
    margin-top: 70px;
  }
`
