import styled from 'styled-components'
import { BsDownload } from 'react-icons/bs'

import {
  secondaryColor,
  cardColor,
  whiteColorText,
  boxShadowColor,
} from '../../colors'

export const Container = styled.div`
  height: 800px;
  max-width: 70rem;
  margin: 90px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid white; */

  @media screen and (max-width: 374px) {
    height: 680px;
  }
`

export const Card = styled.div`
  width: 350px;
  height: max-content;
  border-radius: 14px;
  background-color: ${cardColor};
  box-shadow: 0 5px 15px ${boxShadowColor};
  /* border: 1px solid white; */
  transition: transform 0.3s;
  margin: 50px 0;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  :hover {
    transform: translate3d(0, -10px, 0) !important;
    animation-duration: 0.3s !important;
    transition-duration: 0.3s !important;
    box-shadow: 0 0 3px ${secondaryColor};
  }

  @media screen and (max-width: 375px) {
    width: 300px;
  }

  @media screen and (max-width: 320px) {
    width: 300px;
    margin-top: 170px;
  }
`

export const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  border: 3px solid ${whiteColorText};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }

  @media screen and (max-width: 355px) {
    max-width: 300px;

    img {
      max-width: 300px;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  height: max-content;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${secondaryColor};
  color: ${whiteColorText};

  h3 {
    font-size: 1.3rem;
    letter-spacing: 0.3px;
    padding: 5px 0;
  }

  span {
    font-size: 0.9rem;
    padding: 5px 0 15px 0;
    letter-spacing: 0.3px;
    font-style: italic;
    opacity: 0.8;
  }
`

export const Buttons = styled.div`
  /* border: 1px solid white; */
  margin: 40px 0 25px 0;
  width: 90%;
  height: max-content;
`

export const ButtonStyled = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 4px;
  text-decoration: none;
  color: ${whiteColorText};
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid ${secondaryColor};
  letter-spacing: 2px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;

  &:hover {
    background-color: ${secondaryColor};
  }

  .icon {
    /* border: 1px solid white; */
    font-size: 1.5rem;
    position: absolute;
    left: 20px;
  }
`

export const IconDownload = styled(BsDownload)`
  /* border: 1px solid white; */
  font-size: 1.5rem;
  position: absolute;
  left: 20px;
`
