import styled from 'styled-components'

import {
  bgColorModal,
  colorTextModal,
  primaryColor,
  secondaryColor,
  whiteColorText,
} from '../../colors'

export const ModalDiv = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${bgColorModal};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
`

export const Container = styled.div`
  background-color: ${primaryColor};
  color: ${whiteColorText};
  width: 600px;
  height: max-content;
  border-radius: 8px;
  position: relative;
  padding-bottom: 15px;

  @media (max-width: 615px) {
    width: 350px;
  }

  button {
    display: flex;
    justify-content: center;
    width: 32px;
    height: 32px;
    right: calc(-100% + 45px);
    border-radius: 50%;
    background-color: transparent;
    border: none;
    outline: none;
    color: ${whiteColorText};
    font-size: 1.5rem;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
    z-index: 100;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 2.5px;
      height: 24px;
      background-color: ${whiteColorText};
      top: 3px;
      left: 14px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
`

export const Content = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;

  @media (max-width: 615px) {
    flex-direction: column;
    gap: 10px;

    img {
      height: 45%;
    }
  }

  @media (max-width: 320px) {
    img {
      height: 43%;
    }
  }

  img {
    max-width: 600px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const Header = styled.div`
  width: 100%;
  padding: 0 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 400px;

  @media (max-width: 615px) {
    padding: 0 !important;
    margin-top: 220px;
    justify-content: center;

    h3 {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  h3 {
    color: ${secondaryColor};
    font-size: 1.2rem;
  }

  span {
    color: ${whiteColorText};
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.1rem;
  }

  @media (max-width: 320px) {
    margin-top: 170px;

    h3 {
      font-size: 1rem;
      margin-bottom: 15px;
    }
    span {
      font-size: 1rem;
    }
  }
`

export const Details = styled.div`
  width: 80%;
  height: max-content;
  margin: 0.5rem 1.5rem;
  height: max-content;

  @media (max-width: 615px) {
    margin: 0.5rem 2.4rem;

    p {
      font-size: 0.9rem !important;
    }

    h4 {
      margin-top: 20px !important;
      font-size: 1rem !important;
    }

    span {
      font-size: 0.9rem !important;
    }
  }

  p {
    padding: 0.1rem;
    font-size: 1rem;
    color: ${colorTextModal};
  }

  h4 {
    margin-top: 35px;
    color: ${secondaryColor};
    font-size: 1.1rem;
  }

  span {
    color: ${whiteColorText};
    font-size: 1rem;
  }
`
