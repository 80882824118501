import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'

import { primaryColor, secondaryColor, whiteColorText } from '../../colors'

export const Nav = styled.nav`
  margin-top: 5px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1150px) / 2);
  background-color: ${primaryColor};
  z-index: 999;
  /* border: 1px solid white; */
  position: fixed;
  width: 100%;
  top: -5px;
`

export const NavLink = styled(Link)`
  color: ${whiteColorText};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    color: ${secondaryColor};
    font-weight: bold;
  }

  &:hover {
    color: ${secondaryColor};
  }
`

export const Logo = styled.div`
  padding: 0.5rem;
  border: 3px solid;
  border-radius: 50%;
  font-family: 'Roboto Slab', serif;
  color: ${secondaryColor};
`

export const Bars = styled(FaBars)`
  display: none;
  color: ${whiteColorText};

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const NavMenu = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
