import styled from 'styled-components'

export const ButtonStyled = styled.a`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  max-width: max-content;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 1.5rem;

  &:hover {
    transform: scale(1.1);
    font-size: 1rem;
  }
`
