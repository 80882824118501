import React from 'react'
import { FooterContainer } from './styles'

const Footer = () => {
  return (
    <FooterContainer>Portfólio © Todos os direitos reservados</FooterContainer>
  )
}

export default Footer
