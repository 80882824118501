import styled from 'styled-components'

import {
  secondaryColor,
  headerCardColor,
  cardColor,
  whiteColorText,
  boxShadowColor,
  colorTextGray,
} from '../../colors'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`

export const Formations = styled.div`
  flex: 1;
  min-width: 300px;
  height: 350px;
  background-color: ${cardColor};
  box-shadow: 0 5px 15px ${boxShadowColor};
  border-radius: 20px;

  @media screen and (max-width: 996px) {
    height: 370px;
  }

  @media screen and (max-width: 831px) {
    height: 380px;
  }

  @media screen and (max-width: 780px) {
    height: 400px;
  }

  @media screen and (max-width: 751px) {
    height: 420px;
  }

  @media screen and (max-width: 671px) {
    height: 440px;
  }

  @media screen and (max-width: 663px) {
    height: 350px;
  }

  @media screen and (max-width: 498px) {
    height: 370px;
  }

  @media screen and (max-width: 410px) {
    height: 390px;
  }

  @media screen and (max-width: 390px) {
    height: 410px;
  }

  @media screen and (max-width: 376px) {
    height: max-content;
  }
`

export const Title = styled.div`
  /* border: 1px solid white; */
  padding: 15px 0;
  text-align: center;
  background-color: ${headerCardColor};
  border-radius: 20px 20px 0 0;

  h2 {
    font-size: 1.3rem;
    color: ${whiteColorText};
  }
`

export const Institution = styled.div`
  border: 1px solid ${secondaryColor};
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;

  h3 {
    font-size: 1rem;
    color: ${secondaryColor};
    margin-bottom: 5px;
  }

  span {
    font-size: 0.9rem;
    opacity: 0.9;
  }

  p {
    margin-top: 30px;
    font-size: 0.8rem;
    color: ${colorTextGray};
  }

  .tecs {
    font-size: 1rem;
    color: ${whiteColorText};
  }
`
