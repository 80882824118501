import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

import {
  primaryColor,
  secondaryColor,
  headerCardColor,
  cardColor,
  whiteColorText,
  borderButtonColor,
  boxShadowColor,
  colorButtonRepository,
  colorButtonOnline,
} from '../../colors'

export const Container = styled.div`
  max-width: 70rem;
  margin: 140px auto 50px auto;
  height: max-content;
  /* border: 1px solid white; */

  @media screen and (max-width: 375px) {
    margin-bottom: 0px;
  }

  @media screen and (max-width: 320px) {
    overflow-x: hidden !important;
  }
`

export const ButtonBack = styled.div`
  margin: 0px 20px 50px 20px;
  display: none;
  /* border: 1px solid white; */

  @media screen and (max-width: 768px) {
    display: block;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1rem;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  color: ${whiteColorText};
  font-weight: bold;
  max-width: max-content;
  border: 1px solid ${secondaryColor};
  cursor: pointer;
  transition: all 0.7s;
  background: ${primaryColor};
  letter-spacing: 3px;

  :hover {
    background-color: ${secondaryColor};
  }
`

export const Title = styled.div`
  position: relative;
  text-align: center;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${secondaryColor};
    bottom: 0;
    left: 0;
  }

  h1 {
    font-size: 1.5rem;
    padding: 20px 0;
    color: ${whiteColorText};
  }
`

export const CardContainer = styled.div`
  margin: 50px 0;
  display: flex;
  justify-content: center;
  /* border: 1px solid white; */
`

export const Card = styled.div`
  width: 750px;
  height: max-content;
  border-radius: 20px;
  background-color: ${cardColor};
  box-shadow: 0 5px 15px ${boxShadowColor};
  /* border: 1px solid white; */
  padding-bottom: 5px;
`

export const TitleCard = styled.div`
  /* border: 1px solid white; */
  padding: 15px 0;
  text-align: center;
  background-color: ${headerCardColor};
  border-radius: 20px 20px 0 0;

  h2 {
    font-size: 1.3rem;
    color: ${whiteColorText};
  }
`

export const ContentCard = styled.div`
  /* border: 1px solid white; */
  margin: 25px;
  height: max-content;

  p {
    line-height: 1.5;
    letter-spacing: 1px;
    font-size: 1rem;
    position: relative;
    padding-left: 15px;
    /* border: 1px solid white; */
    margin-bottom: 30px;
  }

  p::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: ${secondaryColor};
    top: 10px;
    left: 0;
    border-radius: 50%;
  }

  .tecs,
  .func {
    margin-bottom: 30px !important;
    color: ${whiteColorText};
    font-weight: bold;
    font-size: 1rem;
    height: 30px;
  }

  .tecs span,
  .date span {
    color: ${secondaryColor};
    line-height: 2;
    font-size: 0.9rem;
    margin-left: 5px;
  }

  @media screen and (max-width: 680px) {
    .tecs {
      margin-bottom: 40px !important;
    }
  }

  @media screen and (max-width: 385px) {
    .tecs {
      margin-bottom: 50px !important;
    }
  }

  .func {
    height: max-content;
    margin-bottom: 35px;
  }

  .func span {
    line-height: 1.5;
    font-size: 0.9rem;
    margin-left: 5px;
    font-weight: 400;
  }

  .func::after {
    top: 8px;
  }

  .api {
    margin-bottom: 30px;
    color: ${whiteColorText};
    font-weight: bold;
    font-size: 1rem;
    height: 30px;
  }

  .api a {
    color: ${secondaryColor};
    letter-spacing: 3px;
    font-size: 0.9rem;
    margin-left: 5px;
    font-weight: 400;
  }

  .api a:hover {
    color: ${secondaryColor};
  }

  .date {
    color: ${whiteColorText};
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0px;
  }
`

export const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const Repository = styled.a`
  width: max-content;
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  color: ${whiteColorText};
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  letter-spacing: 1px;
  background-color: ${headerCardColor};
  border: 1px solid ${borderButtonColor};
  margin-right: 20px;

  &:hover {
    background-color: ${colorButtonRepository};
  }

  @media screen and (max-width: 561px) {
    margin-bottom: 20px;
  }
`

export const Online = styled.a`
  width: max-content;
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  color: ${whiteColorText};
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  letter-spacing: 1px;
  background-color: ${colorButtonOnline};
  border: 1px solid ${borderButtonColor};

  &:hover {
    background-color: ${secondaryColor};
  }
`

export const ContainerScreen = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid white; */
`

export const TitleScreen = styled.div`
  /* border: 1px solid white; */
  position: relative;
  text-align: center;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${secondaryColor};
    bottom: 0;
    left: 0;
  }

  h1 {
    font-size: 1.5rem;
    padding: 20px 0;
  }
`

export const Screens = styled.div`
  max-width: 1120px;
  margin: 50px auto;
  /* border: 1px solid white; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: max-content;
  border-radius: 20px;
  background-color: ${cardColor};
  box-shadow: 0 5px 15px ${boxShadowColor};

  @media screen and (max-width: 1090px) {
    max-width: 900px;
  }

  @media screen and (max-width: 875px) {
    max-width: 700px;
  }

  @media screen and (max-width: 680px) {
    max-width: 500px;
  }

  @media screen and (max-width: 485px) {
    max-width: 400px;
  }

  @media screen and (max-width: 375px) {
    max-width: 300px;
    margin-bottom: 0;
  }

  img {
    max-width: 400px;
    /* margin: 30px 0; */
    border-radius: 12px;
    margin-bottom: 30px;
  }

  img:nth-child(-n + 2) {
    margin-top: 30px;
  }

  img:nth-child(n + 5) {
    margin-bottom: 30px;
  }

  .img-web {
    max-width: 1070px;
    margin-bottom: 30px;
    /* border: 1px solid white; */
  }

  .img-receitas {
    max-height: 840px;
  }

  @media screen and (max-width: 1090px) {
    .img-web {
      max-width: 850px;
    }
  }

  @media screen and (max-width: 875px) {
    .img-web {
      max-width: 650px;
    }
  }

  @media screen and (max-width: 680px) {
    .img-web {
      max-width: 450px;
    }
    h3 {
      text-align: center;
    }
  }

  @media screen and (max-width: 485px) {
    .img-web {
      max-width: 350px;
    }
    h3 {
      padding: 0 20px;
    }
    .mobile {
      height: 800px !important;
    }
    .mobile img {
      max-width: 330px;
    }
    .img-receitas {
      max-height: 700px;
    }
  }

  @media screen and (max-width: 375px) {
    .img-web {
      max-width: 250px;
    }
    .mobile {
      height: max-content !important;
      margin: 10px 20px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .mobile img {
      max-width: 250px;
    }
  }

  h3 {
    font-size: 1.3rem;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .title {
    padding-top: 60px;
  }

  .mobile {
    /* border: 1px solid white; */
    margin: 30px 0;
    padding: 20px 5px 0px 5px;
    height: 920px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${headerCardColor};
    border-radius: 14px;
  }
`
