import React, { useEffect, useState } from 'react'
import { Card, ImageContainer, Content, NavLink } from './styles'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ProjectCard = ({ dataProject }) => {
  const [emulatorImg, setEmulatorImg] = useState('')
  const [emulatorTitle, setEmulatorTitle] = useState('')
  const [emulatorDesc, setEmulatorDesc] = useState('')

  useEffect(() => {
    const { capa, titulo, descricaoCard } = dataProject
    setTimeout(() => {
      setEmulatorImg(capa)
      setEmulatorTitle(titulo)
      setEmulatorDesc(descricaoCard)
    }, 1900)
  }, [dataProject])

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      {width >= 356 ? (
        <Card data-aos="fade-up">
          <ImageContainer>
            {emulatorImg && <img src={emulatorImg} alt={emulatorTitle} />}
            {!emulatorImg && (
              <Skeleton
                baseColor="#DDDBDD"
                count={1}
                width="350px"
                height="200px"
              />
            )}
          </ImageContainer>

          <Content>
            <div className="title">
              <h3>
                {emulatorTitle || (
                  <Skeleton
                    baseColor="#DDDBDD"
                    count={1}
                    width="200px"
                    height="30px"
                  />
                )}
              </h3>
            </div>
            <p>
              {emulatorDesc || (
                <Skeleton
                  baseColor="#ffffff"
                  count={1}
                  width="308px"
                  height="75px"
                />
              )}
            </p>
          </Content>

          {emulatorDesc ? (
            <NavLink
              to={`/projetos/${dataProject.tipo}/${dataProject.tituloURL}`}
            >
              + Visualizar detalhes
            </NavLink>
          ) : (
            <Skeleton
              baseColor="#DDDBDD"
              count={1}
              width="308px"
              height="40px"
              style={{ marginLeft: '20px' }}
            />
          )}
        </Card>
      ) : (
        <Card data-aos="fade-up">
          <ImageContainer>
            {emulatorImg && <img src={emulatorImg} alt={emulatorTitle} />}
            {!emulatorImg && (
              <Skeleton
                baseColor="#DDDBDD"
                count={1}
                width="300px"
                height="200px"
              />
            )}
          </ImageContainer>

          <Content>
            <div className="title">
              <h3>
                {emulatorTitle || (
                  <Skeleton
                    baseColor="#DDDBDD"
                    count={1}
                    width="200px"
                    height="30px"
                  />
                )}
              </h3>
            </div>
            <p>
              {emulatorDesc || (
                <Skeleton
                  baseColor="#ffffff"
                  count={1}
                  width="260px"
                  height="75px"
                />
              )}
            </p>
          </Content>

          {emulatorDesc ? (
            <NavLink
              to={`/projetos/${dataProject.tipo}/${dataProject.tituloURL}`}
            >
              + Visualizar detalhes
            </NavLink>
          ) : (
            <Skeleton
              baseColor="#DDDBDD"
              count={1}
              width="260px"
              height="40px"
              style={{ marginLeft: '20px' }}
            />
          )}
        </Card>
      )}
    </>
  )
}

export default ProjectCard
