import React from 'react'
import { ButtonStyled } from './styles'

const Button = ({
  href,
  download,
  type,
  target,
  children,
  style,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <ButtonStyled
      href={href}
      download={download}
      type={type}
      target={target}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </ButtonStyled>
  )
}

export default Button
