import styled from 'styled-components'

import { secondaryColor } from '../../colors'

export const Container = styled.div`
  max-width: 70rem;
  margin: 140px auto 50px auto;
  height: max-content;
  /* border: 1px solid white; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  padding: 0 5px;
  gap: 1.8rem;

  @media screen and (max-width: 1150px) {
    justify-content: center;
  }

  @media screen and (max-width: 1118px) {
    padding: 0;
  }
`

export const Title = styled.div`
  position: relative;
  text-align: center;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${secondaryColor};
    bottom: 0;
    left: 0;
  }

  h1 {
    font-size: 1.5rem;
    padding: 20px 0;
  }
`
